import React, {Component} from 'react';
import {TextField, withStyles} from "@material-ui/core";
import ErrorFormatter from "../ErrorFormatter";
import PropTypes from 'prop-types';

// Maximum allowed password length by Cognito. This is further clamped to 99 in the password validators.
// Idea is to allow login attempts up to the Cognito maximum even if our UI's dont allow more than 99.
const COGNITO_MAX_PASSWORD_LENGTH = 256

const styles = theme => ({
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  InputLabel: {
    fontSize: "1.2em"
  },
  errorListText: {
    color: "crimson",
    minWidth: "100%",
    textAlign: "left",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    margin: "0px",
    fontSize: '13px'
  },
  validationErrorsList: {
    padding: "0px"
  },
})

export class PasswordField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationErrors: [],
      password: ''
    };
  }

  handleUpdate = (event, isLosingFocus = false) => {
    try {
      let newState = this.state;
      newState.password = event.target.value;
      newState.validationErrors = [];
      const { match, oldPwd } = this.props;

      if (event.target.value && (event.target.value.length >= 4 || isLosingFocus === true)) {
        newState.validationErrors.push(...this.props.passwordValidator.validatePasswordFormat(event.target.value));
        newState.validationErrors.push(...this.props.passwordValidator.validatePasswordRules(event.target.value, match, oldPwd));
      } else if (event.target.value  === "") {
        newState.validationErrors = [];
      }

      this.setState(newState);
      this.props.onChange({
        target: {
          name: this.props.name,
          password: newState.password,
          valid: newState.validationErrors.length === 0 && newState.password.length > 0
        }
      });
    } catch(error) {
      console.log(`handleUpdate Error: ${error.message}`, error.stack); //eslint-disable-line
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <TextField
          id={this.props.id}
          error={this.state.validationErrors.length > 0}
          className={classes.textField}
          inputProps={{
            maxLength: COGNITO_MAX_PASSWORD_LENGTH
          }}
          InputLabelProps={{
            className: classes.InputLabel,
          }}
          type="password"
          name={this.props.name}
          onChange={this.handleUpdate}
          onFocus={(e) => this.handleUpdate(e, false)}
          onBlur={(e) => this.handleUpdate(e, true)}
          label={this.props.label}
          fullWidth={true}
        />
        <ErrorFormatter validationErrors={this.state.validationErrors}/>
      </React.Fragment>
    )
  }
}

PasswordField.propTypes = {
  passwordValidator: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.string,
  oldPwd: PropTypes.string,
  label: PropTypes.oneOf([
    PropTypes.func,
    PropTypes.string,
    PropTypes.object
  ]),
};

export default withStyles(styles)(PasswordField);