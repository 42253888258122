import React, {Component} from 'react';
import {Button, Grid, TextField, Typography, withStyles} from "@material-ui/core";
import {injectIntl, intlShape} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {sendSmsMfaCode} from "../../redux/actions/loginactioncreators";
import messages from "../../messages";
import SaveDevice from "./SaveDevice";
import ResendVerificationCode from "./ResendVerificationCode";
import challengeShape from "../../proptypes/challengeShape";
import { navigateToLogin } from "../../redux/helpers/navigators";
import PropTypes from 'prop-types';

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  error: {
    backgroundColor: "red",
    color: "white",
    minWidth: "100%",
    textAlign: "center",
    padding: "5px"
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  }
});

class MfaCodeEntry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mfaCode: "",
      saveDevice: false,
    }
  }

  handleBackToUserNameEntry = () => {
    navigateToLogin();
  }

  handleOnChange = event => {
    let newState = this.state;
    newState.mfaCode = event.target.value;
    this.setState(newState);
  }

  handleOnSubmit = () => {
    let newState = this.state;
    newState.loginErrorMessages = [];
    this.setState(newState);
    this.props.sendSmsMfaCode(this.state.mfaCode);
  }

  render() {
    const {formatMessage} = this.props.intl;
    const {classes, challenge} = this.props;
    let destination = this.props.challenge
      && challenge.challengeParameters
      && challenge.challengeParameters.CODE_DELIVERY_DESTINATION
      || "";
    return (
      <main className={classes.layout}>
        <Grid item xs={12}>
          <Grid container justify="flex-start">
            <Grid item xs={12}>
              <Typography variant="h6">{formatMessage(messages.mfaEnterCode)}</Typography>
              <Typography>
                {formatMessage(messages.smsDestination, {destination})}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="flex-start">
            <Grid item xs={12}>
              <ResendVerificationCode/>
            </Grid>
          </Grid>
          <Grid container justify="flex-start">
            <Grid item xs={12}>
              <TextField
                autoFocus={true}
                className={classes.textField}
                onChange={this.handleOnChange}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    this.handleOnSubmit();
                    ev.preventDefault();
                  }
                }}
                id="mfacode"
                name="mfacode"
                label={formatMessage(messages.multiFactorSmsCodeEntry)}
                fullWidth={true}
              />
            </Grid>
          </Grid>

          <Grid container justify="flex-start">
            <Grid item xs={12}>
              <SaveDevice/>
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <Grid item
                className={classes.tenPxPadRight}>
              <Button
                id="backToUserNameEntry"
                name="backToUserNameEntry"
                onClick={this.handleBackToUserNameEntry}
                variant="outlined"
                color="primary"
                className={classes.boldFont}
              >
                {formatMessage(messages.backToUserNameEntry)}
              </Button>
            </Grid>
            <Grid item>
              <Button
                id="submit"
                name="submitVerify"
                color="primary"
                variant={this.state.mfaCode.length !== 6 ? 'outlined' : "contained"}
                onClick={this.handleOnSubmit}
                disabled={this.state.mfaCode.length !== 6}
              >
                {formatMessage(messages.smsVerifyButton)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </main>
    )
  }
}

MfaCodeEntry.propTypes = {
  challenge: challengeShape,
  intl: intlShape,
  sendSmsMfaCode: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  username: PropTypes.string,
  password: PropTypes.string,
  complete: PropTypes.bool,
  saveDevice: PropTypes.bool,
}
const mapStateToProps = state => {
  return {
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge,
    deviceKey: state.loginForm.deviceKey,
    password: state.loginForm.password,
    username: state.loginForm.username,
    saveDevice: state.loginForm.saveDevice
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    sendSmsMfaCode
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(MfaCodeEntry)))