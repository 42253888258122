import React, {Component} from 'react';
import {List, ListItem, Typography, withStyles} from "@material-ui/core";
import styles from "../styles";
import {injectIntl, intlShape} from 'react-intl';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
export class ErrorFormatter extends Component {
  render() {
    const {classes} = this.props;
    const {formatMessage} = this.props.intl;
    return (
      <React.Fragment>
        {this.props.validationErrors.length > 0 &&
        <List className={classes.validationErrorsList}>
          {this.props.validationErrors
          .map((validationError)=>{
            // Rather than throw runtime errors, allow bespoke object format alongside plain messages
            if (validationError?.message?.id !== undefined) {
              return validationError;
            } else if (validationError?.id) {
              return {message: validationError}
            }
            console.warn("Validation error did not contain a localizable message.")
          })
          .filter((error)=>error?.message?.id)
          .map((validationError, index) => {
            return (
              <ListItem className={this.props.classes.errorListText}
                        key={index}>
                <Typography id={this.props.id || null} className={this.props.classes.errorListTextItem}
                  variant="subtitle1">{formatMessage(validationError.message, validationError.context)}</Typography>
              </ListItem>
            )
          })
          }
        </List>
        }
      </React.Fragment>
    )
  }
}

ErrorFormatter.propTypes = {
  intl: intlShape,
  classes: PropTypes.object.isRequired,
  validationErrors: PropTypes.array,
  id: PropTypes.string,

}
const mapStateToProps = state => {
  return {
    locale: state.intl.locale,
  }
}
export default connect(mapStateToProps)(injectIntl(withStyles(styles)(ErrorFormatter)));