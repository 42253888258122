import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {withStyles, Typography} from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import styles from "../styles";

class Blurb extends Component {
  getLearnMoreUrl() {
    switch (this.props.locale.toLowerCase()) {
      case 'fr-ca':
        return 'https://www.sterlingcheck.com/client-experience/';
      case 'en-us':
      default:
        return 'https://www.sterlingcheck.com/client-experience/';
    }
  }

  clickHandler = () => {
    (window.open(this.getLearnMoreUrl(), '_blank')).focus()
  }

  render() {
    // use styles from https://portal.dev.sterling.app/components/#Banner, but not background colors,
    // based on the assumption that the fonts, etc. should be the same as were used for the
    // corresponding message in S1, and that they match those used on the mockup attached to
    // the ticket (PLAT-6757) -- not putting these in a distinct CSS since we're using a "MuiTheme"
    // for now and this blurb is temporary
    const {classes} = this.props;
    return (
      <div>
        <Typography variant="body1">
            <FormattedMessage
              id="login.newExperienceBlurb1"
              defaultMessage={`Sterling – a leading global provider of background screening and identity services – provides the foundation of trust and safety that spans across industries, professions and borders.`}
            />
        </Typography>
        <br/>
        <Typography variant="body1">
                  <FormattedMessage
                    id="login.newExperienceBlurb2"
                    defaultMessage={`Sterling has introduced a seamless, simplified (and always secure) login experience. `}
                  />
        </Typography>
        <br/>
        <span onClick={this.clickHandler}>
          <ArrowForwardIcon color="primary" fontSize="small" className={classes.blurbArrow} />
          <span className={classes.learnMoreBlurb}>
          <FormattedMessage
            id="login.newExperienceContext"
            defaultMessage={`Learn More`}
          />
          </span>
        </span>
        <br/>
        <br/>
    </div>
    );
  }
}

Blurb.propTypes = {
  locale: PropTypes.string,
  classes: PropTypes.object
}

const mapStateToProps = state => {
  return {
    locale: state.intl.locale
  }
};
export default connect(mapStateToProps)(withStyles(styles)(Blurb));