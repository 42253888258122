import React, {Component} from 'react';
import {Button, Grid, Typography, withStyles} from "@material-ui/core";
import {injectIntl, intlShape} from 'react-intl';
import {connect} from 'react-redux';
import MfaMobilePhone from './MfaMobilePhone'
import MfaTotp from './MfaTotp';
import {bindActionCreators} from "redux";
import {mfaSetupComplete} from "../../redux/actions/loginactioncreators";
import messages from "../../messages";
import styles from "../../styles";
import challengeShape from "../../proptypes/challengeShape";
import { navigateToLogin } from "../../redux/helpers/navigators";
import PropTypes from 'prop-types';

class MfaSetup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileNumber: "",
    }
  }

  handleBackToUserNameEntry = () => {
    navigateToLogin();
  }

  handleChange = value => {
    this.setState({
      mobileNumber: value,
    });
  };

  handleOnSubmit = () => {
    let newState = this.state;
    newState.loginErrorMessages = [];
    this.setState(newState);
    this.props.mfaSetupComplete();
  }

  render() {
    const {classes, mfaComplete} = this.props;
    const {formatMessage} = this.props.intl;
    const totpEnabled = mfaComplete.includes("TOTP");
    const smsEnabled = mfaComplete.includes("SMS");
    return (
      <main className={classes.layout}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">{formatMessage(messages.mfaSetupTitle)}</Typography>
            <Typography>{formatMessage(messages.mfaSetupBlurb)}</Typography>
            <br/>
            <MfaMobilePhone complete={smsEnabled}></MfaMobilePhone>
            <MfaTotp complete={totpEnabled}></MfaTotp>

            {/*{!this.props.challenge.challengeParameters['REQUIRED'] &&*/}
            {/*<Button variant="contained" color="primary">{formatMessage(messages.mfaSkip)}</Button>*/}
            {/*}*/}
          </Grid>

        </Grid>
        <Grid container justify="flex-end">
          <Grid item className={classes.tenPxPadRight}>
            <Button
              id="backToUserNameEntry"
              name="backToUserNameEntry"
              onClick={this.handleBackToUserNameEntry}
              variant="outlined"
              color="primary"
              className={classes.boldFont}
            >
              {formatMessage(messages.backToUserNameEntry)}
            </Button>
          </Grid>
          <Grid item>
            <Button
              id="mfaSetupComplete"
              name="mfaSetupComplete"
              onClick={this.handleOnSubmit}
              variant='contained'
              color="primary"
              disabled={mfaComplete.length < 1}
            >
              {formatMessage(messages.mfaComplete)}
            </Button>
          </Grid>
        </Grid>
      </main>
    )
  }
}
MfaSetup.propTypes = {
  challenge: challengeShape,
  intl: intlShape,
  mfaSetupComplete: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  mfaComplete: PropTypes.array
}
const mapStateToProps = state => {
  return {
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge,
    extraData: state.loginForm.extraData,
    userInfo: state.loginForm.userInfo,
    mfaComplete: state.loginForm.mfaComplete
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    mfaSetupComplete
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(MfaSetup)))
