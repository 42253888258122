import React, {Component} from 'react';
import {Checkbox, FormControlLabel, withStyles} from "@material-ui/core";
import messages from "../../messages";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {injectIntl, intlShape} from 'react-intl';
import {saveDevice} from '../../redux/actions/loginactioncreators'
import PropTypes from 'prop-types';
const styles = () => ({
  saveDevice: {
    padding: '12px'
  }
})

class SaveDevice extends Component {
  handleSaveDevice = (_saveDeviceAction, value) => {
    //TODO Test this, might not work COR-1814
    this.props.saveDevice(value);
  }

  render() {
    const {classes} = this.props;
    const {formatMessage} = this.props.intl;
    return (
      <div className={classes.saveDevice}>
        <FormControlLabel
          control={
            <Checkbox
              id="saveDevice"
              color="primary"
              onChange={this.handleSaveDevice}
              value='saveDevice'
            />
          }
          label={formatMessage(messages.mfaSaveDevice)}
        />
      </div>
    )
  }
}

SaveDevice.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape,
  saveDevice: PropTypes.func.isRequired,
}
const mapStateToProps = state => {
  return {
    locale: state.intl.locale,
    saveDevice: state.loginForm.saveDevice
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    saveDevice
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(SaveDevice)));