import React, {Component} from 'react';
import {Button, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {injectIntl, intlShape} from 'react-intl';
import HyperLink from "./HyperLink";
import messages from "../messages";
import styles from "../styles";
import PropTypes from 'prop-types';

export class ContactUsLink extends Component {

    /**
     * @return {string}
     */
    getContactUsUrl() {
        const locale = typeof (this.props || {}).locale === 'undefined' ? "" : this.props.locale;
        switch(locale.toLowerCase()) {
            case 'fr-ca':
                return 'https://www.sterlingtalentsolutions.ca/fr/platform-contact/';
            case 'en-us':
            default:
                return 'https://www.sterlingcheck.com/platform-contact/';
        }
    }

    render() {
        const {classes} = this.props;
        const {formatMessage} = this.props.intl;
        return (
            <Button
            fullWidth
            component={HyperLink}
            id="contactUs"
            name="contactUs"
            target="_blank"
            to={this.getContactUsUrl()}
            className={classes.contactUsLink}
            >
                {formatMessage(messages.contactUsText)}
            </Button>
        )
    }
}

ContactUsLink.propTypes = {
    locale: PropTypes.string,
    
}

ContactUsLink.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape,
  locale: PropTypes.string
}
const mapStateToProps = state => {
  return {
    contactUsUrl: state.loginForm.contactUsUrl,
    locale: state.intl.locale
  }
};
export default connect(mapStateToProps)(injectIntl(withStyles(styles)(ContactUsLink)));