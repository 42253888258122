import React, {Component} from 'react';
import {connect} from "react-redux";
import {TextField, withStyles} from "@material-ui/core";
import styles from "../../styles";
import messages from "../../messages";
import {injectIntl, intlShape} from 'react-intl';
import {bindActionCreators} from "redux";
import {loginWithPasswordWatcher, setPassword} from "../../redux/actions/loginactioncreators";
import ErrorFormatter from "../ErrorFormatter";
import PropTypes from 'prop-types';

// Maximum allowed password length by Cognito. This is further clamped to 99 in the password validators.
// Idea is to allow login attempts up to the Cognito maximum even if our UI's dont allow more than 99.
const COGNITO_MAX_PASSWORD_LENGTH = 256

export class LoginFormPassword extends Component {
  handlePasswordChange = event => {
    this.props.setPassword(event.target.value);
  }

  handleOnSubmit = () => {
    let loginParams = {
      username: this.props.username.trim().toLowerCase(),
      password: this.props.password.trim(),
    }
    this.props.loginWithPasswordWatcher(
      loginParams
    )
  }

  render() {
    const {classes} = this.props;
    const {formatMessage} = this.props.intl;
    return (
      <React.Fragment>
        <form>
          <TextField
            className={classes.textField}
            InputLabelProps={{
              className: classes.InputLabel,
            }}
            error={this.props.passwordErrors.length > 0}
            onChange={this.handlePasswordChange}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                this.handleOnSubmit();
                ev.preventDefault();
              }
            }}
            id="password"
            type="password"
            label={formatMessage(messages.password)}
            autoFocus={true}
            fullWidth={true}
            inputProps={{
              maxLength: COGNITO_MAX_PASSWORD_LENGTH
            }}
          />
        </form>
        <ErrorFormatter id="loginPasswordErrors" validationErrors={this.props.passwordErrors}/>
      </React.Fragment>
    )
  }
}

LoginFormPassword.propTypes = {
  setPassword: PropTypes.func.isRequired,
  loginWithPasswordWatcher: PropTypes.func.isRequired,
  username: PropTypes.string,
  password: PropTypes.string,
  classes: PropTypes.object.isRequired,
  intl: intlShape,
  passwordErrors: PropTypes.array,
}
const mapStateToProps = state => {
  return {
    username: state.loginForm.username,
    password: state.loginForm.password,
    locale: state.intl.locale,
    deviceKey: state.loginForm.deviceKey,
    passwordErrors: state.loginForm.passwordErrors
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setPassword,
    loginWithPasswordWatcher
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(LoginFormPassword)));