// deprecated (use shared version)
import messages from "../../messages";

class CognitoPasswordValidator {

  /**
   * @param {number} minimumLength
   * @param {number} maximumLength
   * @param {boolean} requireNumbers
   * @param {boolean} requireSpecialCharacters
   * @param {boolean} requireUppercase
   * @param {boolean} requireLowercase
   */
  constructor(minimumLength, maximumLength, requireNumbers, requireSpecialCharacters, requireUppercase, requireLowercase) {
    this.minimumLength = minimumLength > 6 ? minimumLength : 6;
    this.maximumLength = maximumLength < 99 ? maximumLength : 99;
    this.requireNumbers = requireNumbers;
    this.requireSpecialCharacters = requireSpecialCharacters;
    this.requireUppercase = requireUppercase;
    this.requireLowercase = requireLowercase;
  }

  /**
   * @param {string} password
   * @returns {Array}
   */
  validatePasswordFormat(password) {
    const errors = [];
    if (password.length < this.minimumLength) {
      errors.push({message: messages.passwordMinLength, context: {minLength: this.minimumLength}});
    }
    if (password.length > this.maximumLength) {
      errors.push({message: messages.passwordMaxLength, context: {maxLength: this.maximumLength}});
    }
    if (this.requireNumbers && password.search(/\d/) === -1) {
      errors.push({message: messages.passwordNumeric, context: {}});
    }
    if (this.requireUppercase && password.search(/[A-Z]/) === -1) {
      errors.push({message: messages.passwordUppercase, context: {}});
    }
    if (this.requireLowercase && password.search(/[a-z]/) === -1) {
      errors.push({message: messages.passwordLowercase, context: {}});
    }
    if (this.requireSpecialCharacters && password.search(/[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]/) === -1) {
      errors.push({
        message: messages.passwordSpecial,
        context: {specialCharacters: '^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < \' : ; | _ ~ `'}
      });
    }
    return errors;
  }

  /**
   * @param {string} newPassword - new password value
   * @param {string} match - other password to compare against
   * @param {string} oldPassword - current password to be changed
   * @returns {Array}
   */
  validatePasswordRules(newPassword, match, oldPassword ) {
    const errors = [];

    if (newPassword && oldPassword && match) {
      if (oldPassword === newPassword || oldPassword === match) {
        errors.push({message: messages.newPasswordMustBeDifferent, context: {}});
      }
    }

    if (newPassword && match) {
      if (newPassword.length > 4 && match.length > 4 && newPassword !== match) {
        errors.push({message: messages.changePasswordPasswordsDoNotMatch, context: {}});
      }
    }

    return errors;
  }
}

export default CognitoPasswordValidator;