import React, { Component } from 'react';
import { Button, Grid, TextField, Typography, withMobileDialog, withStyles } from "@material-ui/core";
import {injectIntl, intlShape} from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { sendTotpCode } from "../../redux/actions/loginactioncreators";
import { navigateToLogin } from "../../redux/helpers/navigators";
import messages from "../../messages";
import SaveDevice from "./SaveDevice";
import PropTypes from 'prop-types';
const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  error: {
    backgroundColor: "red",
    color: "white",
    minWidth: "100%",
    textAlign: "center",
    padding: "5px"
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  }
});

class MfaTotpCodeEntry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mfaCode: "",
    }
  }

  handleBackToUserNameEntry = () => {
    navigateToLogin();
  }

  handleOnChange = event => {
    let newState = this.state;
    newState.mfaCode = event.target.value;
    this.setState(newState);
  }
  handleOnSubmit = () => {
    let newState = this.state;
    newState.loginErrorMessages = [];
    try {
      this.props.sendTotpCode(this.state.mfaCode);
    } catch (err) {
      newState.loginErrorMessages = [err.message]
    }
    this.setState(newState);
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    return (
      <main className={classes.layout}>
        <Grid item xs={12}>
          <Typography variant="h6">{formatMessage(messages.mfaEnterCode)}</Typography>
          <Typography>{formatMessage(messages.totpBlurb)}</Typography>
          <TextField
            autoFocus={true}
            className={classes.textField}
            onChange={this.handleOnChange}
            id="mfacode"
            name="mfacode"
            label={formatMessage(messages.multiFactorTotpCodeEntry)}
            fullWidth={true}
          />

          <Grid container justify="flex-start">
            <Grid item xs={12}>
              <SaveDevice />
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
          <Grid item className={classes.tenPxPadRight}>
              <Button
                id="backToUserNameEntry"
                name="backToUserNameEntry"
                onClick={this.handleBackToUserNameEntry}
                variant="outlined"
                color="primary"
                className={classes.boldFont}
              >
                {formatMessage(messages.backToUserNameEntry)}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                id="submit"
                name="submit"
                variant="contained" color="primary"
                onClick={this.handleOnSubmit}
              >
                {formatMessage(messages.totpVerifyButton)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </main>
    )
  }
}

MfaTotpCodeEntry.propTypes = {
  intl:intlShape,
  saveDevice: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  sendTotpCode: PropTypes.func.isRequired,
}
const mapStateToProps = state => {
  return {
    loginErrorMessages: state.loginForm.loginErrorMessages,
    saveDevice: state.loginForm.saveDevice
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    sendTotpCode
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(withMobileDialog()(MfaTotpCodeEntry))))