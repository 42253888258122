import actiontypes from "../actions/actiontypes";
import {handleSetLoginPasswordErrors, handleError} from "../actions/loginactioncreators";
import {call, put, takeLatest} from 'redux-saga/effects'
import CognitoPasswordValidator from "../../components/changePasswordForm/CognitoPasswordValidator"; // todo: switch this ref to shared version
import messages from "../../messages";

let cognitoPasswordValidator = new CognitoPasswordValidator(8, 99, true, true, true, true);

export function* validatePasswordAsync(action) {
  try {
    let errors = yield call(validatePassword, action.payload);
    yield put(handleSetLoginPasswordErrors(errors));
  } catch (error) {
    yield put(handleError(error));
  }
}

export function validatePassword(password) {
  const errors = cognitoPasswordValidator.validatePasswordFormat(password);
  if (errors.length > 0) {
    errors.push({message: messages.passwordRequirements, context: {}});
  }
  return errors;
}

export function* watchValidatePasswordAsync() {
  yield takeLatest(actiontypes.SET_PASSWORD_VALIDATION, validatePasswordAsync);
}