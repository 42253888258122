import React, { Component } from 'react';
import { Button, CircularProgress, Grid, Typography, Paper, withStyles } from "@material-ui/core";
import {injectIntl, intlShape} from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { sendTotpCode, setTotpSpinning } from "../../redux/actions/loginactioncreators";
import * as authenticator from 'authenticator';
import * as QRCode from 'qrcode.react';
import EnablePaper from './EnablePaper';
import messages from "../../messages";
import challengeShape from "../../proptypes/challengeShape";
import PropTypes from 'prop-types';
import userInfoShape from "../../proptypes/userInfoShape";
import Textbox from "@sterling-react/textbox"

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  error: {
    backgroundColor: "red",
    color: "white",
    minWidth: "100%",
    textAlign: "center",
    padding: "5px"
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  signInButton: {
    marginTop: theme.spacing.unit * 5,
  },
  verifyButton: {
    marginTop: "16px"
  },
  gridMargin: {
    marginTop: "16px"
  }
});

class MfaTotp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totpCode: '',
      totpCodeError: false,
      totpCodeValid: false,
    }
  }

  validate(totpCode) {
    if (totpCode.length === 6) {
      try {
        return authenticator.verifyToken(this.props.secretCode, totpCode);
      } catch (error) {
        console.error("Error while trying to validate token", error)
      }
    }
    return false;
  }

  handleTotpChange = event => {
    let valid = false;
    let error = false;
    if (event.target.value.length === 6) {
      valid = this.validate(event.target.value);
      error = !valid;
    }
    this.setState({
      totpCode: event.target.value,
      totpCodeValid: valid,
      totpCodeError: error
    });

  }
  handleClick = () => {
    const valid = this.validate(this.state.totpCode);
    if (valid) {
      this.props.sendTotpCode(this.state.totpCode)
    } else {
      this.setState({
        totpCodeInvalid: true,
        totpCodeError: true
      })
    }
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    let qrCode = false
    if (this.props.secretCode) {
      qrCode = authenticator.generateTotpUri(this.props.secretCode, this.props.userInfo.UserAttributes.email, 'Sterling Talent Solutions', 'SHA1', 6, 30);
    }
    return (
      <EnablePaper
        complete={this.props.complete}
        title={formatMessage(messages.totpSetup)}
        name="totp"
        id="totp"
      >

        {this.props.spinning &&
          <CircularProgress />
        }

        {!this.props.spinning &&
          <React.Fragment>
            <Grid container className={classes.gridMargin}>
              <Grid item xs={12}>
                <Typography>{formatMessage(messages.totpSetupBlurb)}
                </Typography>
              </Grid>
            </Grid>
            {qrCode &&
              <React.Fragment>
                <Grid container justify="center" className={classes.gridMargin}>
                  <Grid item>
                    <QRCode value={qrCode} />
                    <input type="hidden" id="totpUri" value={qrCode} />
                  </Grid>
                </Grid>
                <Grid container className={classes.gridMargin}>
                  <Grid item xs={12}>
                    <Typography>{formatMessage(messages.totpSetupScanBlurb)}</Typography>
                  </Grid>
                </Grid>
                <Grid container className={classes.gridMargin}>
                  <Grid item xs={12}>
                    <Typography>
                      {formatMessage(messages.totpSetupSecretCodeLabel)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className={classes.gridMargin}>
                  <Grid item xs={12}>
                    <Paper square={true} id="totpCode" elevation={0} className={classes.fivePxPadding}>
                      <Typography variant="caption">
                        {this.props.secretCode.split('').reduce((a, e, i) => a + e + (i % 4 === 3 ? ' ' : ''), '')}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </React.Fragment>
            }


            <Grid container className={classes.gridMargin}>
              <Grid item xs={8}>
                <Textbox
                  fullWidth
                  error={this.state.totpCodeError}
                  onChange={this.handleTotpChange}
                  label={formatMessage(messages.totpSetupVerificationCode)}
                  id="verificationCode"
                  name="verificationCode"
                  autoFocus
                  errorText={formatMessage(messages.CodeMismatchException)}
                />
              </Grid>

              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth={true}
                  className={classes.verifyButton}
                  disabled={!this.state.totpCodeValid}
                  variant='contained'
                  color="primary"
                  id="totpCodeNext"
                  name="totpCodeNext"
                  onClick={this.handleClick}
                >
                  {formatMessage(messages.totpSaveButton)}
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        }

      </EnablePaper>
    )
  }
}

MfaTotp.propTypes = {
  classes: PropTypes.object.isRequired,
  challenge: challengeShape,
  intl: intlShape,
  spinning: PropTypes.bool,
  secretCode: PropTypes.string,
  deviceKey: PropTypes.string,
  sendTotpCode: PropTypes.func.isRequired,
  complete: PropTypes.bool,
  password: PropTypes.string,
  totpSession: PropTypes.string,
  userInfo: userInfoShape
}
const mapStateToProps = state => {
  return {
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge,
    userInfo: state.loginForm.userInfo,
    username: state.loginForm.username,
    password: state.loginForm.password,
    deviceKey: state.loginForm.deviceKey,
    spinning: state.loginForm.totpSpinning,
    secretCode: state.loginForm.secretCode || null,
    totpSession: state.loginForm.totpSession || null
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    sendTotpCode,
    setTotpSpinning
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(MfaTotp)))