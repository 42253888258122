import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  withMobileDialog,
  withStyles
} from "@material-ui/core";
import {injectIntl, intlShape} from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { cancelResetPasswordWatcher, resetPasswordWatcher } from '../../redux/actions/loginactioncreators';
import messages from "../../messages";
import PropTypes from 'prop-types';
import challengeShape from "../../proptypes/challengeShape";
import LoaderOverlay from './../LoaderOverlayMask'
const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  InputLabel: {
    fontSize: "1.2em"
  },
  error: {
    backgroundColor: "red",
    color: "white",
    minWidth: "100%",
    textAlign: "center",
    padding: "5px"
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  signInButton: {
    marginTop: theme.spacing.unit * 5,
  }
});

export class ResetPasswordForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loginErrorMessages: [],
      validationErrors: {},
      username: null,
      password: null
    }
  }

  handleCloseResetPassword = () => {
    this.props.cancelResetPasswordWatcher()
  }
  handleCodeChange = event => {
    let state = this.state;
    state.code = event.target.value;
    this.setState(state);
  }
  handlePasswordChange = event => {
    let state = this.state;
    state.password = event.target.value;
    this.setState(state);
  }
  handlePasswordConfirmChange = event => {
    let state = this.state;
    state.passwordConfirm = event.target.value;
    this.setState(state);
  }
  handleOnSubmit = () => {
    let newState = this.state;
    newState.loginErrorMessages = [];
    this.setState(newState);
    const validated = this.validateResetPassword();
    if (validated) {
      this.props.resetPasswordWatcher(
        {
          step: "CONFIRM_RESET_PASSWORD",
          username: this.props.username.trim().toLowerCase(),
          code: this.state.code,
          password: this.state.password.trim()
        }
      )
    }
  }

  validateResetPassword() {
    let state = this.state;
    let valid = false;
    if (!this.state.code) {
      state.validationErrors.code = true;
    } else {
      state.validationErrors.code = false;
      valid = true;
    }
    if (!this.state.password) {
      state.validationErrors.password = true;
    } else {
      state.validationErrors.password = false;
      valid = true;
    }
    if (!this.state.passwordConfirm) {
      state.validationErrors.passwordConfirm = true;
    } else {
      state.validationErrors.passwordConfirm = false;
      valid = true;
    }
    this.setState(state);
    return valid;
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    const deliveryMedium = this.props.challenge.challengeParameters.CodeDeliveryDetails.DeliveryMedium;
    let deliveryMediumText = formatMessage(messages.deliveryMediumEmail);
    if (deliveryMedium === 'SMS') {
      deliveryMediumText = formatMessage(messages.deliveryMediumSms);
    }
    return (
      <React.Fragment>

        <Dialog open={this.props.open}>
          <LoaderOverlay>

            <DialogTitle>{formatMessage(messages.forgotPasswordTitle)}</DialogTitle>

            <React.Fragment>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {this.props.challenge.challengeParameters &&
                    <Typography>{formatMessage(messages.forgotPasswordBlurb, {
                      deliveryMedium: deliveryMediumText,
                      destination: this.props.challenge.challengeParameters.CodeDeliveryDetails.Destination
                    })}</Typography>
                  }
                  {deliveryMedium === 'EMAIL' &&
                    <Typography>{formatMessage(messages.emailSender, {sender: "noreply@sterling.app"})}</Typography>
                  }
                  <Typography>{formatMessage(messages.forgotPasswordMfaWarning)}</Typography>
                </DialogContentText>
                <Grid container justify="center">
                  <Grid item xs={12}>
                    <Typography align="left">

                    </Typography>
                  </Grid>
                  <br />
                  <Grid item xs={5}>
                    <TextField
                      error={this.state.validationErrors.code}
                      className={classes.textField}
                      InputLabelProps={{
                        className: classes.InputLabel,
                      }}
                      onChange={this.handleCodeChange}
                      id="code"
                      name="code"
                      label={formatMessage(messages.codeEntry)}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={7}></Grid>
                  <Grid item xs={5}>
                    <TextField
                      type="password"
                      error={this.state.validationErrors.password}
                      className={classes.textField}
                      InputLabelProps={{
                        className: classes.InputLabel,
                      }}
                      onChange={this.handlePasswordChange}
                      id="newPassword"
                      name="newPassword"
                      label={formatMessage(messages.password)}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5}>
                    <TextField
                      type="password"
                      error={this.state.validationErrors.passwordConfirm}
                      className={classes.textField}
                      onChange={this.handlePasswordConfirmChange}
                      id="newPasswordConfirm"
                      name="newPasswordConfirm"
                      label={formatMessage(messages.passwordConfirm)}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  id="cancelResetPassword"
                  name="cancelResetPassword"
                  onClick={this.handleCloseResetPassword}
                  className={classes.signInButton}
                  variant="contained"
                  color="secondary">{formatMessage(messages.cancel)}
                </Button>
                <Button
                  id="submitResetPassword"
                  name="submitResetPassword"
                  onClick={this.handleOnSubmit}
                  className={classes.signInButton}
                  variant="contained"
                  color="primary">{formatMessage(messages.resetPassword)}</Button>
              </DialogActions>
            </React.Fragment>
          </LoaderOverlay>
        </Dialog>

      </React.Fragment>
    )
  }
}

ResetPasswordForm.propTypes = {
  open: PropTypes.bool,
  username: PropTypes.string,
  classes: PropTypes.object.isRequired,
  challenge: challengeShape,
  intl: intlShape,
  resetPasswordWatcher: PropTypes.func.isRequired,
  cancelResetPasswordWatcher: PropTypes.func.isRequired,
}
const mapStateToProps = state => {
  return {
    username: state.loginForm.username,
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge || null
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    resetPasswordWatcher,
    cancelResetPasswordWatcher
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(withMobileDialog()(ResetPasswordForm))))