import React, {Component} from 'react';
import LocalizationSelect from "./LocalizationSelect";
import {withStyles, Grid} from "@material-ui/core";
import styles from "../styles";
import ContactUsLink from "./ContactUsLink";
import PropTypes from 'prop-types';
class Footer extends Component {
  render() {
    const {classes} = this.props;
    return (
      <Grid container className={classes.footer}>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={6}>
              <LocalizationSelect/>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container justify="flex-end">
            <Grid item xs={5}>
              <ContactUsLink/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(Footer);