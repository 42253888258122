import {call, put, select, takeLatest} from 'redux-saga/effects'
import { loginWithPassword } from '../../lib/services'
import { handleChallengeWatcher, loginWatcher, handleError, setMfaTypeSetupComplete } from "../actions/loginactioncreators"
import BigInteger from "amazon-cognito-identity-js/lib/BigInteger";
import AuthHelper from '../authhelper';
import {
  loginSetFlowStageWatcher, setDeviceData, setPassword
} from "../actions/loginactioncreators";
import getDeviceData from "../helpers/getDeviceData";
import getUsername from "../helpers/getUsername";
import {normalizePayload} from "../helpers/normalizePayload";
import getClientId from "../helpers/getClientId";
import {navigateToAuthorize} from "../helpers/navigators";
import getLocale from "../helpers/getLocale";
import getUserPoolId from "../helpers/getUserPoolId";
import flowStages from "../../flowStages";
import challenges from "../../challenges";
import actiontypes from "../actions/actiontypes";

let authHelper;

function* legacyLoginAsync(action) {
  try {
    const AmazonCognitoAdvancedSecurityData = window['AmazonCognitoAdvancedSecurityData'];
    const username = yield select(getUsername);
    const clientId = yield select(getClientId);
    const userPoolId = yield select(getUserPoolId);
    const encodedData = AmazonCognitoAdvancedSecurityData.getData(username, userPoolId, clientId);
    let payload = normalizePayload(action.payload);
    payload.userContextData = encodedData;
    const deviceData = yield select(getDeviceData, username);
    const currentTime = new Date().getTime();
    if (deviceData.deviceKey && deviceData.expires > currentTime) {
      payload.deviceKey = deviceData.deviceKey;
    }
    if (clientId) {
      payload.client_id = clientId;
    }
    const locale = yield select(getLocale);
    const options = {
      headers: {
        'Accept-Language': locale,
      }
    };
    let {data} = yield loginWithPassword(payload, options);
    if (data.flowStage === flowStages.CHALLENGE || data.flowStage === flowStages.CHALLENGE_MFA_SETUP) {
      if (data.challenge) {
        if (data.challenge.step === "MFA_SETUP_COMPLETE") {
          if (action.payload?.challengeResponse?.step === "MFA_SETUP_TOTP") {
            yield put(setMfaTypeSetupComplete("TOTP"))
          } else {
            yield put(setMfaTypeSetupComplete("SMS"))
          }
        } else if (data.challenge.challengeName === challenges.DEVICE_SRP_AUTH) {
          if (!authHelper) {
            authHelper = new AuthHelper(deviceData.deviceGroupKey)
          }
          const srpA = yield call([authHelper, 'getLargeAValue']);
          if (srpA) {
            yield put(loginWatcher({
              flowStage: "CHALLENGE",
              challengeResponse: {
                session: data.session,
                challengeName: data.challenge.challengeName,
                challengeParameters: {
                  USERNAME: data.challenge.challengeParameters.USER_ID_FOR_SRP,
                  DEVICE_KEY: deviceData.deviceKey,
                  SRP_A: srpA.toString(16)
                }
              }
            }));
          }
        } else if (data.challenge.challengeName === challenges.DEVICE_PASSWORD_VERIFIER) {
          if (!authHelper) {
            authHelper = new AuthHelper(deviceData.deviceGroupKey)
          }
          let params = {
            username: data.challenge.challengeParameters.USER_ID_FOR_SRP,
            deviceGroupKey: deviceData.deviceGroupKey,
            deviceKey: deviceData.deviceKey,
            devicePassword: deviceData.devicePassword,
            serverBValue: new BigInteger(data.challenge.challengeParameters.SRP_B, 16),
            salt: new BigInteger(data.challenge.challengeParameters.SALT, 16),
            secretBlock: data.challenge.challengeParameters.SECRET_BLOCK
          };
          const challengeResponses = yield call(
            [authHelper, 'getPasswordAuthenticationKey'], params
          );
          if (challengeResponses) {
            yield put(loginWatcher({
              flowStage: flowStages.CHALLENGE,
              challengeResponse: {
                session: data.session,
                challengeName: data.challenge.challengeName,
                challengeParameters: challengeResponses
              }
            }));
          }
        } else {
          yield put(handleChallengeWatcher(data));
        }
      }
    } else if (data.flowStage === flowStages.AUTHENTICATED || data.flowStage === flowStages.AUTHENTICATED_FIRST_TIME) {
      if (data.deviceKey) {
        const today = new Date();
        const expireDate = new Date().setDate(today.getDate() + 30);
        const username = yield select(getUsername);
        yield put(setDeviceData(username.trim().toLowerCase(), data.deviceKey, data.deviceGroupKey, data.devicePassword, expireDate));
      }
      if (data.flowStage === flowStages.AUTHENTICATED) {
        navigateToAuthorize();
      } else {
        yield put(loginSetFlowStageWatcher(data));
      }
    }
    if(payload.challengeResponse && payload.challengeResponse.challengeName === challenges.NEW_PASSWORD_REQUIRED) {
      yield put(setPassword(payload.challengeResponse.challengeParameters.NEW_PASSWORD));
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

export function* watchLegacyLoginAsync() {
  yield takeLatest(actiontypes.USER_LOGIN_LEGACY, legacyLoginAsync);
}