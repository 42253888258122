import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core";
import { connect } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';

const styles = (theme) => ({
    container: {
        border: "1px solid black",
        backgroundColor: "#b80a05",
        color: "white",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up('md')]: {
            flexDirection: "row",
        },
        fontWeight: "normal",
        fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
        margin: "0 0",
        padding: "10px 5px",
        width: "100%",
    },
    item: {
        margin: "0",
        padding: "10px 5px",
        textAlign: "left",
    },
    itemLeft: {
        fontWeight: "600",
        minWidth: "250px",
        padding: "0",
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            padding: "0 10px 0 0",
            textAlign: "right",
        },
    },
    itemMiddle: {
        padding: "10px 20px",
        textAlign: "center",
        width: "100%",
        [theme.breakpoints.up('md')]: {
            padding: "0 0",
        },
    },
    itemRight: {
        color: "white",
        minWidth: "250px",
        padding: "0",
        textDecoration: "underline",
        textAlign: "center",
        [theme.breakpoints.up('md')]: {
            padding: "0 0 0 10px",
            textAlign: "left",
        },
    },
    linkColor: {
        color: "white"
    },
    icon: {
        position: "relative",
        top: "3px",
    }
})

class AlertBanner extends React.Component {
    render () {
        const { classes, alert } = this.props;

        const linkText = alert.sideText ? alert.sideText : "";

        return (
        <div id="alertBanner" className={classes.container}>
            <div className={classes.itemLeft} ><ErrorIcon className={classes.icon} />  {alert.headingTitle}</div>
            <div id="bannerMessage" className={classes.itemMiddle} >{alert.message}</div>
            <div className={classes.itemRight} ><a className={classes.linkColor} href={alert.sideLink}>{linkText}</a></div>
        </div>
        );
    }
}

AlertBanner.propTypes = {
    classes: PropTypes.object.isRequired,
    alert:  PropTypes.object.isRequired
}

const mapStateToProps = state => ({
        alert: state.loginForm.alert,
    });

export default connect(mapStateToProps)(withStyles(styles)(AlertBanner));