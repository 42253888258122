// todo: consolidate this form and the one in react-sso-landing

import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {loginSetFlowStageWatcher, loginWatcher} from "../../redux/actions/loginactioncreators";
import {bindActionCreators} from "redux";
import messages from "../../messages";
import {Button, Grid, TextField, Typography, withStyles} from "@material-ui/core";
import * as EmailValidator from 'email-validator';
import {injectIntl, intlShape} from 'react-intl';
import challengeShape from "../../proptypes/challengeShape";
import userInfoShape from "../../proptypes/userInfoShape";
import PropTypes from 'prop-types';
import flowStages from "../../flowStages";

const styles = theme => ({
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  error: {
    backgroundColor: "red",
    color: "white",
    minWidth: "100%",
    textAlign: "center",
    padding: "5px"
  },
  errorListText: {
    color: "crimson",
    minWidth: "100%",
    textAlign: "left",
    paddingTop: "0px",
    paddingBottom: "0px",
    margin: "0px"
  },
  InputLabel: {
    fontSize: "1.2em"
  },
  validationErrorsList: {
    padding: "0px"
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  }
});

export class ChangeEmailAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.userInfo.UserAttributes.email,
    }
  }

  handleChangeEmail = event => {
    const email = event.target.value;

    this.setState({email: email});
    this.setState({emailValid: this.validateEmail(email)});
  }
  handleSubmit = () => {
    this.props.loginWatcher({
      flowStage: flowStages.CHANGE_EMAIL_ADDRESS,
      username: this.props.username,
      challengeResponse: {
        session: this.props.challenge.session,
        challengeName: this.props.challenge.challengeName,
        challengeParameters: {
          USERNAME: this.props.challenge.challengeParameters.USER_ID_FOR_SRP,
          NEW_EMAIL: this.state.email,
        }
      }
    });
  }
  handleSkip = () => {
    this.props.loginSetFlowStageWatcher({
      flowStage: flowStages.SKIP_EMAIL_NOT_VERIFIED,
    })
  }

  validateEmail(email) {
    if (!email) {
      return false;
    }
    return EmailValidator.validate(email);
  }

  render() {
    const {classes} = this.props;
    const {formatMessage} = this.props.intl;
    return (
      <Fragment>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Typography variant="body1">{formatMessage(messages.changeEmailBlurb)}</Typography>
            <br/>
            <br/>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="emailAddress"
              name="emailAddress"
              autoFocus={true}
              fullWidth={true}
              className={classes.textField}
              InputLabelProps={{
                className: classes.InputLabel,
              }}
              value={this.state.email}
              label={formatMessage(messages.emailAddress)}
              onChange={this.handleChangeEmail}
            />
          </Grid>
        </Grid>
        <br/>
        <br/>
        <Grid container justify="flex-end">
          <Grid item>
            <Button
              id="skip"
              name="skip"
              onClick={this.handleSkip}
              variant="outlined"
              color="primary"
            >
              {formatMessage(messages.skipEmailSubmit)}
            </Button>
          </Grid>
          <Grid
            item
            className={classes.padLeft10px}
          >
            <Button
              id="submit"
              name="submit"
              disabled={!this.validateEmail(this.state.email)}
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
            >
              {formatMessage(messages.changeEmailSubmit)}
            </Button>
          </Grid>
          <br/>
        </Grid>
      </Fragment>
    )
  }
}

ChangeEmailAddress.propTypes = {
  classes: PropTypes.object.isRequired,
  username: PropTypes.string,
  password: PropTypes.string,
  loginWatcher: PropTypes.func.isRequired,
  userInfo: userInfoShape,
  intl: intlShape,
  challenge: challengeShape,
  loginSetFlowStageWatcher: PropTypes.func.isRequired,
}
const mapStateToProps = state => {
  return {
    userInfo: state.loginForm.userInfo,
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge || null,
    username: state.loginForm.username,
  }
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loginWatcher,
    loginSetFlowStageWatcher
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(ChangeEmailAddress)));