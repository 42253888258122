import actiontypes from "../actions/actiontypes";
import { handleGetProviderWatcher, handleError, resetPasswordWatcher, handleS1Transport } from "../actions/loginactioncreators";
import { put, takeLatest, select} from 'redux-saga/effects'
import { getProvider } from '../../lib/services'
import getLocale from "../helpers/getLocale";
import getClientId from "../helpers/getClientId";
import { ProviderDao } from "../helpers/launchProvider";

export function* getProviderAsync(action) {
  try {
    const clientId = yield select(getClientId);
    let payload = action.payload;
    payload.username = payload.username.trim().toLowerCase();
    payload.client_id = clientId;
    const locale = yield select(getLocale);
    const options = {
      headers: {
        'Accept-Language': locale,
      }
    };
    let { data } = yield getProvider(payload, options);
    if (data.provider.type === 'COGNITO') {
      yield put(handleS1Transport(data.s1TransportUser === true));
      if (data.requirePasswordReset) {
        yield put(resetPasswordWatcher({
          username: payload.username,
          clientId: clientId,
          step: "RESET_PASSWORD"
        }));
      } else {
        yield put(handleGetProviderWatcher(data));
      }
    } else {
      const providerDao = new ProviderDao(clientId);
      providerDao.saveLastLoginProvider(data.provider);
      providerDao.launchProvider(data.provider);
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

export function* watchGetProviderAsync() {
  yield takeLatest(actiontypes.USER_GET_PROVIDER, getProviderAsync);
}