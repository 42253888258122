import React, { Fragment, Component} from "react";
import {injectIntl, intlShape} from "react-intl";
import { Grid, Typography, withStyles } from "@material-ui/core";
import styles from "../../styles";
import messages from "../../messages";
import { navigateToLogout, navigateToApplication } from "../../redux/helpers/navigators";
import PropTypes from "prop-types";
import {BusyButton} from "@sterling-react/button"

export class ExistingSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signOutBusy: false,
      staySignedInBusy: false,
    }
    this.secondsBeforeRetryAvailable = 10;
  }

  handleNavigateToApplication = () => {
    navigateToApplication();
  }
  handleNavigateToLogout = () => {
    navigateToLogout();
  }
  setSignOutBusyForSeconds = (seconds) => {
    this.setState({...this.state, signOutBusy: true});
    setTimeout(() => this.setState({...this.state, signOutBusy: false}), seconds * 1000);
  }
  setStaySignedInBusyForSeconds = (seconds) => {
    this.setState({...this.state, staySignedInBusy: true});
    setTimeout(() => this.setState({...this.state, staySignedInBusy: false}), seconds * 1000);
  }
  
  render() {
    const { formatMessage } = this.props.intl;
    const { classes } = this.props;
    return (
      <Fragment>
        <Grid container>
          <Grid item xs={12}>
            <Typography id="existingSessionMessage">{formatMessage(messages.existingSession)}</Typography>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
        <Grid container justify="flex-end">
          <Grid item>
            <BusyButton
              id="existingSessionSignOut"
              onClick={() => {
                this.setSignOutBusyForSeconds(this.secondsBeforeRetryAvailable);
                this.handleNavigateToLogout();
              }}
              busy={this.state.signOutBusy}
              className="btn btn-md m-2 btn-outlined"
            >
              {formatMessage(messages.existingSessionSignOut)}
            </BusyButton>
          </Grid>
          <Grid item
            className={classes.padLeft10px}>
            <BusyButton
              id="existingSessionStaySignedIn"
              onClick={() => {
                this.setStaySignedInBusyForSeconds(this.secondsBeforeRetryAvailable);
                this.handleNavigateToApplication();
              }}
              busy={this.state.staySignedInBusy}
              className="btn btn-md m-2"
            >
              {formatMessage(messages.existingSessionStaySignedIn)}
            </BusyButton>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

ExistingSession.propTypes = {
  intl: intlShape,
  productName: PropTypes.string,
}

export default injectIntl(withStyles(styles)(ExistingSession));