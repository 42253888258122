import React, { Component } from 'react';
import { withStyles, Grid } from "@material-ui/core";
import PropTypes from 'prop-types';
const styles = theme => ({
  logo: {
    maxWidth: "100%",
    maxHeight: "100%",
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4
  },
  header: {
    maxHeight: '160px'
  }
})

class Header extends Component {
  render() {
    const logoUrl = this.props.logoUrl;
    const { classes } = this.props;
    return (
      <Grid container justify="left">
        <Grid item xs={9} className={classes.header}>
          <img alt="Logo" src={logoUrl} className={classes.logo} />
        </Grid>
      </Grid>
    )
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  logoUrl: PropTypes.string.isRequired,
}
export default withStyles(styles)(Header);