
// todo: consolidate this form and the one in react-sso-landing
import React, {Fragment, Component} from 'react';
import {TextField, Button, Grid, Typography, withMobileDialog, withStyles} from "@material-ui/core";
import {injectIntl, intlShape} from 'react-intl';
import messages from './messages';
import PasswordField from '../fields/PasswordField';
import CognitoPasswordValidator from "./CognitoPasswordValidator";
import PropTypes from 'prop-types';

const styles = theme => ({
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  error: {
    backgroundColor: "red",
    color: "white",
    minWidth: "100%",
    textAlign: "center",
    padding: "5px"
  },
  errorListText: {
    color: "crimson",
    minWidth: "100%",
    textAlign: "left",
    paddingTop: "0px",
    paddingBottom: "0px",
    margin: "0px"
  },
  InputLabel: {
    fontSize: "1.2em"
  },
  validationErrorsList: {
    padding: "0px"
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  }
});

export class ChangePasswordForm extends Component {

  constructor(props) {
    super(props);
    this.passwordValidator = props.passwordValidator;
    this.state = {
      newPassword: {
        value: '',
        valid: false
      },
      oldPassword: {
        value: '',
        valid: false
      },
      verifyPassword: {
        value: '',
        valid: false
      },
      submitDisabled: true,
    };
  }

  handleOldPasswordUpdate = event => {
    this.setState({
      oldPassword: {
        value: event.target.value
      }
    })
  }

  handleUpdate = event => {
    let state = this.state;
    let passwordsValid;
    state[event.target.name].value = event.target.password;
    state[event.target.name].valid = event.target.valid;

    let passwordsMatch = true;
    if (state.newPassword.value !== state.verifyPassword.value) {
      passwordsMatch = false;
    }

    let passwordNotReused = true;
    if (state.oldPassword.value === state.newPassword.value || state.oldPassword.value === state.verifyPassword.value ) {
      passwordNotReused = false;
    }
    passwordsValid = state.newPassword.valid && state.oldPassword.valid && state.verifyPassword.valid;

    state.submitDisabled = !(passwordsMatch && passwordNotReused && passwordsValid);
    this.setState(state);
  }

  handleOnSubmit = () => {
    this.props.onSubmit(
      {
        oldPassword: this.state.oldPassword.value,
        newPassword: this.state.newPassword.value
      }
    )
  }

  enableSubmit = () => {
    let state = this.state;
    if (state.newPassword === state.verifyPassword && state.oldPassword.length > 0 && state.oldPassword.value !== state.newPassword.value) {
      state.submitDisabled = false;
      this.setState(state);
    }
  }

  showPasswordResetDialog = () => {
    this.setState({
      showPasswordResetDialog: true
    });
  };

  render() {
    const {classes} = this.props;
    const { formatMessage } = this.props.intl;
    const cognitoPasswordValidator = new CognitoPasswordValidator(8, 99, true, true, true, true)
    return (
      <Fragment>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Typography variant="h6">{formatMessage(messages.changePasswordBlurb)}</Typography>
            <Typography>{formatMessage(messages.changePasswordInstructions)}</Typography>
          <br />
          <br />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="oldPassword"
              name="oldPassword"
              autoFocus={true}
              fullWidth={true}
              className={classes.textField}
              InputLabelProps={{
                className: classes.InputLabel,
              }}
              type="password"
              label={formatMessage(messages.oldPassword)}
              onChange={(evt) => this.handleUpdate({target: {name: "oldPassword", password: evt.target.value, valid: evt.target.value.length > 0}})} />
            <PasswordField
              id="newPassword"
              name="newPassword"
              match={this.state.verifyPassword.value}
              oldPwd={this.state.oldPassword.value}
              passwordValidator={cognitoPasswordValidator}
              label={formatMessage(messages.newPassword)}
              onChange={this.handleUpdate} />
            <PasswordField
              id="verifyPassword"
              name="verifyPassword"
              match={this.state.newPassword.value}
              oldPwd={this.state.oldPassword.value}
              passwordValidator={cognitoPasswordValidator}
              label={formatMessage(messages.verifyPassword)}
              onChange={this.handleUpdate} />
          </Grid>
        </Grid>
        <Grid container justify="flex-end">
          <Grid item>
            <Button
              id="submit"
              name="submit"
              onClick={this.handleOnSubmit}
              disabled={this.state.submitDisabled}
              variant="contained"
              color="primary"
            >
              {formatMessage(messages.submit)}
            </Button>
          </Grid>
          <br />
        </Grid>
      </Fragment>
    )
  }
}
ChangePasswordForm.propTypes = {
  intl: intlShape,
  passwordValidator: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  classes: PropTypes.object.isRequired,

}
export default injectIntl(withStyles(styles)(withMobileDialog()(ChangePasswordForm)))