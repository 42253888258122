import React, { Component } from 'react';
import { withStyles } from "@material-ui/core";
import styles from "../../styles";
import { connect } from "react-redux";
import {injectIntl, intlShape} from 'react-intl';
import { bindActionCreators } from "redux";
import { removeProvider } from "../../redux/actions/loginactioncreators";
import messages from "../../messages";
import {Button} from "@material-ui/core";
import PropTypes from 'prop-types';
import providerShape from "../../proptypes/providerShape";
export class BackToUserNameEntry extends Component {
  handleBackToUserNameEntry = () => {
    this.props.removeProvider();
  }
  render() {
    const { formatMessage } = this.props.intl;
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.props.provider &&
          <Button
            id="backToUserNameEntry"
            name="backToUserNameEntry"
            onClick={this.handleBackToUserNameEntry}
            variant="outlined"
            color="primary"
            className={classes.boldFont}
            disabled={!this.props.provider}
          >
            {formatMessage(messages.backToUserNameEntry)}
          </Button>
        }
      </React.Fragment>
    )
  }
}

BackToUserNameEntry.propTypes = {
  removeProvider: PropTypes.func.isRequired,
  intl: intlShape,
  provider: providerShape
}
const mapStateToProps = state => {
  return {
    loginErrorMessages: state.loginForm.loginErrorMessages,
    username: state.loginForm.username,
    provider: state.loginForm.provider
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    removeProvider
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(BackToUserNameEntry)));