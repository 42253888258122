import React from 'react';
import {withStyles} from "@material-ui/core";

import {SnackbarProvider, withSnackbar} from 'notistack';
import styles from "../styles";
import Login from "./Login";
import AlertBanner from "./AlertBanner";

import PropTypes from 'prop-types';
import {Grid, Hidden} from "@material-ui/core";
import LoaderOverlay from './LoaderOverlayMask'

function GetAlertBanner(prop) {
  if (prop.showAlertBanner) {
    return (<AlertBanner />)
  } else {
    return null;
  }
}
class LoginWidget extends React.Component {
  render() {
    const { classes } = this.props;
    const picturePath = `${this.props.baseUrl}/assets/${this.props.splashImage}`;
    const pictureAltText = "Splash image";
    const LoginWithSnackbar = withSnackbar(Login);

    return (
      <SnackbarProvider maxSnack={1} anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}>
        <LoaderOverlay
          styles={{
            overlay: (base) => ({
              ...base,
              background: 'rgba(0, 0, 0, 0.1)'
            })
          }}
        >
          <GetAlertBanner {...this.props} />

          <Grid container>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={10} sm={4}>
              <LoginWithSnackbar
                header={this.props.header}
                defaultLocale={this.props.locale}></LoginWithSnackbar>
            </Grid>
            <Grid item xs={1}>
            </Grid>
            <Hidden smDown>
              <Grid item md={6} lg={6}>
                <picture id="splash" className='z-0 relative'>
                  <source srcSet={`${picturePath}.webp`} class={classes.pictureStyle} alt={pictureAltText} type="image/webp"/>
                  <source srcSet={`${picturePath}.jpeg`} class={classes.pictureStyle} alt={pictureAltText} type="image/jpeg"/>
                  <img src={`${picturePath}.jpeg`} class={classes.pictureStyle} alt={pictureAltText}/>
                </picture>
              </Grid>
            </Hidden>
          </Grid>
        </LoaderOverlay>
      </SnackbarProvider>
    )
  }
}

LoginWidget.propTypes = {
  header: PropTypes.string,
  locale: PropTypes.string,
  baseUrl: PropTypes.string,
  splashImage: PropTypes.string,
  showAlertBanner: PropTypes.bool
}
export default withStyles(styles)(LoginWidget);