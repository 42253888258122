import React, { Component } from 'react';
import { Grid, Icon, Paper, Typography, withStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  loginWatcher,
  mfaEnableChoice
} from "../../redux/actions/loginactioncreators";
import PropTypes from 'prop-types';
const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(450 + theme.spacing.unit * 3 * 2)]: {
      width: 450,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  error: {
    backgroundColor: "red",
    color: "white",
    minWidth: "100%",
    textAlign: "center",
    padding: "5px"
  },
  paper: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  signInButton: {
    marginTop: theme.spacing.unit * 5,
  },
  // enablePaper: {
  //   marginTop: '10px',
  //   marginBottom: '10px',
  //   border: "1px solid #eeeeee",
  //   borderRadius: '4px',
  //   padding: '0px'
  // },
  enablePaper: {
    marginTop: "10px",
    marginBottom: "10px",
    border: "1px solid #cccccc",
    borderRadius: "2px 0px 0px 2px"
  },
  enablePaperEnabled: {
    marginTop: "10px",
    marginBottom: "10px",
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "2px 0px 0px 2px"
  },
  enablePaperContent: {},
  enablePaperComplete: {
    backgroundColor: theme.palette.primary.main,
    display: "inline-block",
    borderRadius: "2px 0px 0px 2px",
    paddingTop: "25px",
    color: "white",
    textAlign: "center"
  },
  enablePaperInner: {
    paddingTop: "26px",
    paddingBottom: "26px"
  },
  enablePaperInner2: {
    width: "100%",
    paddingBottom: "26px"
  }
});

class EnablePaper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enabled: false,
    }
  }

  handleEnable = () => {
    if (!this.props.complete) {
      const enabled = !this.props.mfa[this.props.id].enabled;

      this.props.mfaEnableChoice(this.props.id, enabled);
      if (enabled && this.props.onEnable) {
        this.props.onEnable();
      }
    }
  }

  render() {
    const { classes } = this.props;
    const enabled = this.props.mfa[this.props.id].enabled;
    return (
      <React.Fragment>
        <Paper name={this.props.name} id={this.props.id} elevation={0} onClick={(e) => {
          !enabled && this.handleEnable(e)
        }} className={(enabled || this.props.complete) ? classes.enablePaperEnabled : classes.enablePaper}>
          <Grid container alignItems="stretch">
            {this.props.complete &&
              <Grid item xs={1} className={classes.enablePaperComplete}>
                {/* eslint-disable-next-line */}
                <Icon>check</Icon>
              </Grid>
            }
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <div className={classes.enablePaperInner}>
                <Grid container>
                  <Grid item >
                    <Typography variant="subtitle1" className={classes.boldFont}>{this.props.title}</Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            {(!this.props.complete) && enabled &&
              <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                  <Grid container>
                    <div className={classes.enablePaperInner2}>
                      {this.props.children}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        </Paper>
      </React.Fragment>
    )
  }
}

EnablePaper.propTypes = {
  onEnable: PropTypes.func,
  classes: PropTypes.object.isRequired,
  complete: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.array,
  mfa: PropTypes.object,
  mfaEnableChoice: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge,
    userInfo: state.loginForm.userInfo,
    username: state.loginForm.username,
    password: state.loginForm.password,
    deviceKey: state.loginForm.deviceKey,
    mfa: state.loginForm.mfa
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loginWatcher,
    mfaEnableChoice
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(EnablePaper)))