// todo: this should probably be shared between react-sso-landing and react-sso-login
import { defineMessages } from "react-intl";

export default defineMessages({
  changePasswordBlurb: {
    id: "changePassword.changePasswordBlurb",
    defaultMessage: "Your password must be changed.",
    description: "Reset password dialog instruction text",
  },
  changePasswordInstructions: {
    id: "changePassword.changePasswordInstructions",
    defaultMessage: "Enter your current password and then enter your new one.",
    description: "Change password instructions"
  },
  oldPassword: {
    id: "changePassword.oldPassword",
    defaultMessage: "Current password",
    description: "Current password field text"
  },
  newPassword: {
    id: "changePassword.newPassword",
    defaultMessage: "New password",
    description: "New password field text"
  },
  verifyPassword: {
    id: "changePassword.verifyPassword",
    defaultMessage: "Confirm new password",
    description: "Confirm new password field text"
  },
  next: {
    id: "changePassword.next",
    defaultMessage: "Next",
    description: "Next Button"
  },
  submit: {
    id: "changePassword.submit",
    defaultMessage: "Change password",
    description: "Confirm password change button"
  },
});