import React, {Component, Fragment} from 'react';
import styles from "../../styles";
import {connect} from "react-redux";
import {
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import messages from "../../messages";
import {bindActionCreators} from "redux";
import {
  getProviderWatcher,
  setUsername
} from "../../redux/actions/loginactioncreators";
import {injectIntl, intlShape} from 'react-intl';
import providerShape from "../../proptypes/providerShape";
import PropTypes from 'prop-types';
import ErrorFormatter from "../ErrorFormatter";

const COGNITO_MAX_USERNAME_LENGTH = 128

export class UsernameEntry extends Component {


  constructor(props) {
    super(props);
    this.state = {
      username: '',
      validationErrors: {
        username: false,
        text: []
      }
    }
  }
  clearError() {
    this.setState({...this.state,
      validationErrors: {
        username: false,
        text: []
      }})
  }
  setError(errors) {
    if (errors instanceof Array) {
      this.setState({...this.state, validationErrors: {username: true, text: errors}});
    } else {
      this.setState({...this.state, validationErrors: {username: true}});
    }
  }
  handleGetProvider = () => {
    const username = this.props.username.trim();
    const usernameValid = username.match(/[\p{L}\p{M}\p{S}\p{N}\p{P}]+/u)?.[0].length === username.length;
    if (usernameValid) {
      this.props.getProviderWatcher({username});
      return;
    }
    let errors = [];
    errors.push(messages.InvalidUsername)
    if (username.match(/\s/)) {
      errors.push(messages.UsernameContainsSpaces)
    }
    this.setError(errors);
  }

  handleUsernameChange = event => {
    this.clearError();
    this.props.setUsername(event.target.value);
  }

  render() {
    const {classes} = this.props;
    const {formatMessage} = this.props.intl;

    return (
      <Fragment>
        {!this.props.provider ? (
          <Fragment>
            <TextField
              className={classes.textField}
              InputLabelProps={{
                className: classes.InputLabel,
              }}
              error={!this.props.provider && (this.state.validationErrors.username || this.props.loginErrorMessages.length > 0)}
              onChange={this.handleUsernameChange}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  if (this.props.username.length > 0) {
                    this.handleGetProvider()
                  }
                  ev.preventDefault();
                }
              }}
              id="username"
              label={formatMessage(messages.username)}
              autoFocus={true}
              fullWidth={true}
              value={this.props.username}
              inputProps={{
                maxLength: COGNITO_MAX_USERNAME_LENGTH
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      id="getProvider"
                      name="getProvider"
                      disabled={!(this.props.username) || this.props.username.length === 0}
                      onClick={this.handleGetProvider}
                      variant='contained'
                      color="primary"
                    >
                      <ArrowForwardIcon color={this.props.username ? "primary" : "disabled"}/>
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <ErrorFormatter validationErrors={this.state.validationErrors.text ?? []}/>

            {!this.props.provider &&
            <Grid container justify="flex-end">
              <Grid item>
              </Grid>
            </Grid>
            }
          </Fragment>
        ) : (
          <Fragment>
            <FormHelperText>{formatMessage(messages.username)}</FormHelperText>
            <Typography id="username" variant="body1" color="textSecondary">{this.props.username ? this.props.username.trim().toLowerCase() : ""}</Typography>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

UsernameEntry.propTypes = {
  setUsername: PropTypes.func.isRequired,
  getProviderWatcher: PropTypes.func.isRequired,
  username: PropTypes.string,
  classes: PropTypes.object.isRequired,
  intl: intlShape,
  provider: providerShape,
  loginErrorMessages: PropTypes.array,
}
const mapStateToProps = state => {
  return {
    loginErrorMessages: state.loginForm.loginErrorMessages,
    username: state.loginForm.username,
    provider: state.loginForm.provider
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getProviderWatcher,
    setUsername
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(UsernameEntry)));