import React, { Component } from 'react';
import { Button, Grid, TextField, Typography, withStyles } from "@material-ui/core";
import {injectIntl, intlShape} from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  loginWatcher,
  mfaSetSmsNumber
} from "../../redux/actions/loginactioncreators";
import phone from 'phone';
import EnablePaper from './EnablePaper';
import messages from "../../messages";
import PropTypes from 'prop-types';
import challengeShape from "../../proptypes/challengeShape";
import flowStages from "../../flowStages";
import {InputPhone} from "@sterling-react/input"

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  error: {
    backgroundColor: "red",
    color: "white",
    minWidth: "100%",
    textAlign: "center",
    padding: "5px"
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  signInButton: {
    marginTop: theme.spacing.unit * 5,
  },
  verifyPanel: {
    marginTop: "20px"
  },
  verifyButton: {
    marginTop: "16px"
  },
  resendVerificationCode: {
    marginTop: '5px',
    textTransform: 'initial',
    color: theme.palette.primary.main,
    padding: "0px"
  },
});

class MfaMobilePhone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNumber: '',
      mobileNumberValid: false,
      verificationCode: '',
      verificationCodeValid: false,
    }
  }
  handleChange = (event) => {
    this.props.mfaSetSmsNumber(event?.target?.value);
    this.setState({
      mobileNumberValid: true
    });
  };
  handleClickNext = () => {
    const valid = this.validate(this.props.mfa.mobileNumber.value);
    if (valid) {
      this.props.loginWatcher(
        {
          flowStage: flowStages.CHALLENGE_MFA_SETUP,
          challengeResponse: {
            session: this.props.challenge.session,
            challengeName: this.props.challenge.challengeName,
            step: "MFA_SETUP_SMS",
            challengeParameters: {
              USERNAME: this.props.challenge.challengeParameters.USER_ID_FOR_SRP,
              MFA_CONFIG: {
                PHONE_NUMBER: phone(this.props.mfa.mobileNumber.value)[0]
              },
              PASSWORD: this.props.password
            }
          }
        }
      )
    }
  }

  handleResendVerificationCode = () => {
    const valid = this.validate(this.props.mfa.mobileNumber.value);
    if (valid) {
      this.props.loginWatcher(
        {
          flowStage: flowStages.CHALLENGE_MFA_SETUP,
          challengeResponse: {
            session: this.props.challenge.session,
            challengeName: this.props.challenge.challengeName,
            step: "MFA_SETUP_SMS",
            challengeParameters: {
              USERNAME: this.props.challenge.challengeParameters.USER_ID_FOR_SRP,
              RESEND: 'true',
              MFA_CONFIG: {
                PHONE_NUMBER: phone(this.props.mfa.mobileNumber.value)[0]
              },
              PASSWORD: this.props.password
            }
          }
        }
      )
    }
  }
  validateVerificationCode(verificationCode) {
    if (verificationCode.length === 6) {
      return true;
    }
    return false;
  }

  handleChangeVerify = event => {
    let valid = false;
    if (event.target.value.length === 6) {
      valid = this.validateVerificationCode(event.target.value);
    }
    this.setState({
      verificationCode: event.target.value,
      verificationCodeValid: valid
    });
  }
  handleClickVerify = () => {
    const valid = this.validate(this.props.mfa.mobileNumber.value);
    if (valid) {
      const params = {
        flowStage: flowStages.CHALLENGE_MFA_SETUP,
        challengeResponse: {
          session: this.props.challenge.session,
          challengeName: this.props.challenge.challengeName,
          step: "MFA_SETUP_SMS_VERIFY",
          challengeParameters: {
            USERNAME: this.props.challenge.challengeParameters.USER_ID_FOR_SRP,
            MFA_CONFIG: {
              VERIFICATION_CODE: this.state.verificationCode
            },
            PASSWORD: this.props.password
          }
        }
      };
      this.props.loginWatcher(
        params
      )
    }
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;
    const readyToVerify = this.props.challenge.step === 'MFA_SETUP_SMS_VERIFY';
    const buttonDisabled = this.props.challenge.step === 'MFA_SETUP_SMS_VERIFY' || !this.state.mobileNumberValid;
    return (
      <EnablePaper
        complete={this.props.complete}
        title={formatMessage(messages.smsSetup)}
        name="mobileNumber"
        id="mobileNumber"
      >
        <React.Fragment>
          <Grid container>
            <Grid item xs={9}>
            <InputPhone
                id="mobileNumberEntry"
                name="mobileNumberEntry"
                onChange={this.handleChange.bind(this)}
                testId="phone-input"
                value={this.props.mfa.mobileNumber.value}
                setValueCallback={this.handleChange.bind(this)}
            />
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth={true}
                disabled={buttonDisabled}
                variant='contained'
                color="primary"
                id="phoneNumberNext"
                name="phoneNumberNext"
                onClick={this.handleClickNext}
              >
                {formatMessage(messages.smsSetupPhoneNext)}
              </Button>
            </Grid>
          </Grid>
          {readyToVerify &&
            <React.Fragment>
              <Grid container className={classes.verifyPanel}>
                <Grid item xs={12}>
                  <Typography>{formatMessage(messages.smsSetupPhoneVerifyBlurb)}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    id="submitResend"
                    name="submitResend"
                    className={classes.resendVerificationCode}
                    onClick={this.handleResendVerificationCode}
                  >
                    {formatMessage(messages.smsResendButton)}
                  </Button>
                </Grid>
              </Grid>
              <Grid container className={classes.verifyPanel}>
                <Grid item xs={8}>
                  <TextField
                    autoFocus={true}
                    fullWidth={true}
                    error={this.state.totpCodeError}
                    onChange={this.handleChangeVerify}
                    label={formatMessage(messages.smsSetupPhoneVerificationCode)}
                    id="verificationCode"
                    name="verificationCode"
                  ></TextField>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={3} className={classes.verifyButton}>
                  <Button
                    fullWidth={true}
                    disabled={!this.state.verificationCodeValid}
                    variant='contained'
                    color="primary"
                    id="verificationCodeVerify"
                    name="verificationCodeVerify"
                    onClick={this.handleClickVerify}
                  >
                    {formatMessage(messages.smsSetupPhoneVerify)}
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          }

        </React.Fragment>
      </EnablePaper>
    )
  }
}

MfaMobilePhone.propTypes = {
  challenge: challengeShape,
  intl: intlShape,
  loginWatcher: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  password: PropTypes.string,
  complete: PropTypes.bool,
  mfa: PropTypes.object,
  mfaSetSmsNumber: PropTypes.func.isRequired
}
const mapStateToProps = state => {
  return {
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge,
    userInfo: state.loginForm.userInfo,
    username: state.loginForm.username,
    password: state.loginForm.password,
    deviceKey: state.loginForm.deviceKey,
    mfa: state.loginForm.mfa
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loginWatcher,
    mfaSetSmsNumber
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(MfaMobilePhone)))