// todo: consolidate this form and the one in react-sso-landing

import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {loginWatcher} from "../../redux/actions/loginactioncreators";
import {bindActionCreators} from "redux";
import messages from "../../messages";
import {Button, Grid, TextField, Typography, withStyles} from "@material-ui/core";
import * as EmailValidator from 'email-validator';
import {injectIntl, intlShape} from 'react-intl';
import PropTypes from 'prop-types';
import challengeShape from "../../proptypes/challengeShape";
import userInfoShape from "../../proptypes/userInfoShape";
import flowStages from "../../flowStages";

const styles = theme => ({
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  error: {
    backgroundColor: "red",
    color: "white",
    minWidth: "100%",
    textAlign: "center",
    padding: "5px"
  },
  errorListText: {
    color: "crimson",
    minWidth: "100%",
    textAlign: "left",
    paddingTop: "0px",
    paddingBottom: "0px",
    margin: "0px"
  },
  InputLabel: {
    fontSize: "1.2em"
  },
  validationErrorsList: {
    padding: "0px"
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  }
});

export class ConfirmEmailAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.userInfo.UserAttributes.email,
    }
  }
  handleChangeCode = event => {
    const code = event.target.value;

    this.setState({code: code});
  }
  handleSubmit = () => {
    this.props.loginWatcher({
      flowStage: flowStages.VERIFY_EMAIL,
      username: this.props.username,
      password: this.props.password,
      challengeResponse: {
        session: this.props.challenge.session,
        challengeName: this.props.challenge.challengeName,
        challengeParameters: {
          USERNAME: this.props.challenge.challengeParameters.USER_ID_FOR_SRP,
          CODE: this.state.code,
        }
      }
    });
  }
  validateEmail(email) {
    if(!email) {
      return false;
    }
    return EmailValidator.validate(email);
  }
  render() {
    const {classes} = this.props;
    const {formatMessage} = this.props.intl;
    return (
      <Fragment>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Typography variant="body1">{formatMessage(messages.confirmEmailBlurb, {destination: this.props.challenge.challengeParameters['DESTINATION']})}</Typography>
            <br/>
            <br/>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="confirmEmailAddress"
              name="confirmEmailAddress"
              autoFocus={true}
              fullWidth={true}
              className={classes.textField}
              InputLabelProps={{
                className: classes.InputLabel,
              }}
              label={formatMessage(messages.emailAddressConfirmationCode)}
              onChange={this.handleChangeCode}
            />
          </Grid>
        </Grid>
        <br/>
        <br/>
        <Grid container justify="flex-end">
          <Grid item>
            <Button
              id="submit"
              name="submit"
              disabled={!this.state.code || (this.state.code.length < 6 && this.state.code.length > 6)}
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
            >
              {formatMessage(messages.confirmEmailSubmit)}
            </Button>
          </Grid>
          <br/>
        </Grid>
      </Fragment>
    )
  }
}

ConfirmEmailAddress.propTypes = {
  classes: PropTypes.object.isRequired,
  username: PropTypes.string,
  password: PropTypes.string,
  loginWatcher: PropTypes.func.isRequired,
  userInfo: userInfoShape,
  intl: intlShape,
  challenge: challengeShape,
}
const mapStateToProps = state => {
  return {
    userInfo: state.loginForm.userInfo,
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge || null,
    username: state.loginForm.username,
    password: state.loginForm.password,
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loginWatcher
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(injectIntl(ConfirmEmailAddress)));