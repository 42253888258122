import React, {Component, Fragment} from 'react';
import {addLocaleData, IntlProvider} from 'react-intl';
import {connect} from 'react-redux';
import LoginForm from './login/LoginForm';
import MfaCodeEntry from './mfa/MfaCodeEntry';
import MfaSetup from './mfa/MfaSetup'
import MfaChoice from './mfa/MfaChoice';
import MfaTotpCodeEntry from './mfa/MfaTotpCodeEntry'
import {Paper, withStyles} from "@material-ui/core";
import locales from '../i18n/locales';
import Footer from "./Footer";
import ChangePassword from "./ChangePassword";
import LoginFirstTime from "./login/LoginFirstTime";
import ForgotPassword from './login/ForgotPassword';
import ExistingSession from "./login/ExistingSession";
import ChangeEmailAddress from './email/ChangeEmailAddress';
import ConfirmEmailAddress from "./email/ConfirmEmailAddress";
import SkipChangeEmailAddress from "./email/SkipChangeEmailAddress";
import PropTypes from 'prop-types';
import ForcedResetPassword from "./login/forcedPasswordReset";
import { CircularProgress } from "@material-ui/core";
import { ProviderDao } from "../redux/helpers/launchProvider";
import * as queryString from "query-string";

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  error: {
    backgroundColor: "red",
    color: "white",
    minWidth: "100%",
    textAlign: "center",
    padding: "5px"
  },
  paper: {
    marginTop: theme.spacing.unit * 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    //padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  }
});

class Login extends Component {

  render() {
    let locale = this.props.locale || this.props.defaultLocale;
    let localeData;
    if (locales[locale]) {
      localeData = locales[locale].localeData
    } else {
      locale = 'en-us';
      localeData = locales[locale].localeData
    }
    addLocaleData(Object.assign({locale, parentLocale: 'en'}, localeData));
    this.props.loginErrorMessages.forEach((loginErrorMessage) => {
      this.props.enqueueSnackbar(loginErrorMessage, {variant: 'error'});
    });
    this.props.successMessages.forEach((loginMessage) => {
      this.props.enqueueSnackbar(loginMessage, {variant: 'success'});
    });

    let flowStage = this.props.flowStage;

    const providerDao = new ProviderDao(this.props.client_id);
    const savedProvider = providerDao.getSavedProvider();
    if (
      this.props.enableAutoIdpRedirect === true &&
      providerDao.checkAutoLaunchProvider(savedProvider) === true
    ) {
      flowStage = "REDIRECT_TO_IDP";
    }

    const parsedQueryString = queryString.parse(window.location.search);
    if (
      parsedQueryString.existingSession &&
      parsedQueryString.existingSession === "true"
    ) {
      flowStage = "AUTHENTICATED_CONFIRM_SINGLE_SESSION";
    }

    let LoginComponent;
    switch (flowStage) {
      case 'MFA_SETUP':
        LoginComponent = MfaSetup;
        break;
      case 'SMS_MFA':
        LoginComponent = MfaCodeEntry;
        break;
      case 'SOFTWARE_TOKEN_MFA':
        LoginComponent = MfaTotpCodeEntry;
        break;
      case 'SELECT_MFA_TYPE':
        LoginComponent = MfaChoice;
        break;
      case 'NEW_PASSWORD_REQUIRED':
        LoginComponent = ChangePassword;
        break;
      case 'EXPIRED_NEW_PASSWORD_REQUIRED':
        LoginComponent = ChangePassword;
        break;
      case 'AUTHENTICATED_FIRST_TIME':
        LoginComponent = LoginFirstTime;
        break;
      case 'CONFIRM_RESET_PASSWORD':
        if (this.props.s1TransportUser === true) {
          LoginComponent = ForcedResetPassword;
        } else {
          LoginComponent = ForgotPassword;
        }
        break;
      case 'EMAIL_NOT_VERIFIED':
        LoginComponent = ChangeEmailAddress;
        break;
      case 'VERIFY_EMAIL':
        LoginComponent = ConfirmEmailAddress;
        break;
      case 'SKIP_EMAIL_NOT_VERIFIED':
        LoginComponent = SkipChangeEmailAddress;
        break;
      case 'AUTHENTICATED_CONFIRM_SINGLE_SESSION':
        LoginComponent = ExistingSession;
        break;
      case 'REDIRECT_TO_IDP':
        providerDao.launchProvider(savedProvider);
        LoginComponent = <CircularProgress size={100} />;
        break;
      case 'LOGIN':
      default:
        LoginComponent = LoginForm;
        break;
    }
    const {classes} = this.props;

    return (
      <Fragment>
        <main className={classes.layout}>
          <IntlProvider locale={locale} messages={localeData}>
            <Fragment>
              <Paper elevation={0} className={classes.paper}>
                {this.props.header ? this.props.header : null}
                <LoginComponent/>
              </Paper>
              <Footer/>
            </Fragment>
          </IntlProvider>
        </main>
      </Fragment>
    )
  }
}

Login.propTypes = {
  locale: PropTypes.string,
  defaultLocale: PropTypes.string,
  loginErrorMessages: PropTypes.array,
  enqueueSnackbar: PropTypes.func.isRequired,
  successMessages: PropTypes.array,
  flowStage: PropTypes.string,
  classes: PropTypes.object.isRequired,
  header: PropTypes.func.isRequired,
  s1TransportUser: PropTypes.bool,
  client_id: PropTypes.string,

}
const mapStateToProps = state => {
  return {
    locale: state.intl.locale,
    successMessages: state.loginForm.successMessages,
    enableAutoIdpRedirect: state.loginForm.enableAutoIdpRedirect,
    loginErrorMessages: state.loginForm.loginErrorMessages,
    flowStage: state.loginForm.flowStage,
    s1TransportUser: state.loginForm.s1TransportUser,
    client_id: state.loginForm.clientId,
    wholeState: state
  }
};

export default connect(
  mapStateToProps
)(withStyles(styles)((Login)))