import React, { Fragment, Component } from 'react';
import {injectIntl, intlShape} from 'react-intl';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from "react-redux";
import styles from "../../styles";
import { bindActionCreators } from "redux";
import messages from "../../messages";
import { navigateToApplication, navigateToDashboard } from "../../redux/helpers/navigators";
import PropTypes from 'prop-types';

export class LoginFirstTime extends Component {

  handleNavigateToDashboard = () => {
    navigateToDashboard();
  }
  handleNavigateToApplication = () => {
    navigateToApplication();
  }
  render() {
    const { formatMessage } = this.props.intl;
    const { classes } = this.props;

    return (
      <Fragment>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">{formatMessage(messages.loginFirstTimeSuccess)}</Typography>
            <Typography>{formatMessage(messages.loginFirstTimeBlurb, { product: this.props.productName })}</Typography>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
        <Grid container justify="flex-end">
          <Grid item>
            <Button
              id="goToDashboard"
              onClick={this.handleNavigateToDashboard}
              variant="outlined"
              color="primary"
            >
              {formatMessage(messages.loginFirstTimeContinueToDashboard)}
            </Button>
          </Grid>
          <Grid item
            className={classes.padLeft10px}>
            <Button
              id="autoLaunch"
              onClick={this.handleNavigateToApplication}
              variant="contained"
              color="primary"
              autoFocus={true}
            >
              {formatMessage(messages.loginFirstTimeAutoLaunch)}
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}
LoginFirstTime.propTypes = {
  intl: intlShape,
  productName: PropTypes.string,
}
const mapStateToProps = state => {
  return {
    loginErrorMessages: state.loginForm.loginErrorMessages,
    username: state.loginForm.username,
    provider: state.loginForm.provider,
    productName: state.loginForm.productName
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(LoginFirstTime)));