import React, { Component, Fragment } from "react";
import {
  Button,
  IconButton,
  Checkbox,
  FormControlLabel,
  Grid,
  withMobileDialog,
  withStyles,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  loginWithPasswordWatcher,
  rememberMe,
  removeProvider,
} from "../../redux/actions/loginactioncreators";
import ResetPassword from "./ResetPassword";
import messages from "../../messages";
import styles from "../../styles";
import UsernameEntry from "./UsernameEntry";
import BackToUserNameEntry from "./BackToUserNameEntry";
import LoginFormPassword from "./LoginFormPassword";
import ResetPasswordLink from "./ResetPasswordLink";
import Blurb from "../Blurb";
import PropTypes from "prop-types";
import providerShape from "../../proptypes/providerShape";
import OptOut from "./OptOut";
import { ProviderDao } from "../../redux/helpers/launchProvider";

export class LoginForm extends Component {
  constructor(props) {
    super(props);
    let saveUsername = JSON.parse(localStorage.getItem("save-username"));
    if (!saveUsername) {
      saveUsername = false;
    }
    let username = JSON.parse(localStorage.getItem("username"));
    if (!username) {
      username = "";
    }
    this.providerDao = new ProviderDao(this.props.client_id);
    this.providerDao.initProvider(this.props.autoIdpRedirectProviderData);
    const savedProvider = this.providerDao.getSavedProvider();

    this.state = {
      redirecting: false,
      lastProvider: savedProvider,
      showProviderOptions:
        savedProvider?.type !== "COGNITO" &&
        props.enableAutoIdpRedirect === true,
      enableAutoIdpRedirectBool: props.enableAutoIdpRedirect === true,
      autolaunchProvider: savedProvider?.autoLaunch ?? false,
      showPasswordResetDialog: false,
      loginErrorMessages: [],
      validationErrors: {},
      username: username,
      password: null,
      saveUsername: saveUsername,
    };
  }

  saveUsernameInLocalStorage(save) {
    localStorage.setItem("save-username", JSON.stringify(save));
    if (save) {
      if (this.state.username) {
        localStorage.setItem("username", JSON.stringify(this.state.username));
      }
    } else {
      localStorage.removeItem("username");
    }
  }

  handleLaunchProvider = (event) => {
    this.providerDao.launchProvider(this.state.lastProvider);
    event.preventDefault();
  };

  handleOnSubmit = (event) => {
    let loginParams = {
      username: this.props.username,
      password: this.props.password,
    };
    this.props.loginWithPasswordWatcher(loginParams);
    event.preventDefault();
  };

  validateLogin() {
    let state = this.state;
    let valid = false;
    if (!this.state.username) {
      state.validationErrors.username = true;
    } else {
      state.validationErrors.username = false;
      valid = true;
    }
    if (!this.state.password) {
      state.validationErrors.password = true;
    } else {
      state.validationErrors.password = false;
      valid = true;
    }
    this.setState(state);
    return valid;
  }

  loginWithGoogle = () => {
    window.location =
      "/oauth2/authorize" +
      window.location.search +
      "&identity_provider=Google";
  };
  handleSaveUsername = (event) => {
    this.props.rememberMe(event.target.value);
  };
  toggleAutoLaunchProvider = (event) => {
    this.setState({ ...this.state, autolaunchProvider: event.target.checked });
    this.providerDao.saveLastLoginProvider(
      this.state.lastProvider,
      event.target.checked
    );
  };
  handleDeleteProvider = () => {
    this.setState({ ...this.state, showProviderOptions: undefined });
    this.providerDao.deleteLastLoginProvider();
  };
  handleBack = () => {
    this.setState({ username: "" });
    this.props.removeProvider();
  };

  render() {
    const { classes } = this.props;
    const showPassword = this.props.provider;
    const { formatMessage } = this.props.intl;
    const { enableAutoIdpRedirectBool, showProviderOptions, lastProvider } =
      this.state;
    const idpIdentifier = lastProvider?.idpIdentifier;

    return (
      <React.Fragment>
        <div className={classes.layout}>
          <Blurb />

          {this.props.step === "CONFIRM_RESET_PASSWORD" && (
            <ResetPassword open={true} />
          )}
          <Grid container justify="center">
            <Grid item xs={12}>
              <UsernameEntry />
            </Grid>
          </Grid>
          {showProviderOptions && idpIdentifier && !showPassword && (
            <Grid container justify="flex-start">
              <Grid item xs={12}>
                <Button
                  id="launchProvider"
                  name="launchProvider"
                  onClick={this.handleLaunchProvider}
                  color="primary"
                  variant="contained"
                >
                  {formatMessage(messages.providerText)}
                </Button>
                <IconButton
                  color="primary"
                  aria-label="delete saved provider"
                  component="label"
                  onClick={this.handleDeleteProvider}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                {enableAutoIdpRedirectBool && (
                  <FormControlLabel
                    className={classes.eightPxPadding}
                    labelPlacement="end"
                    control={
                      <Checkbox
                        color="primary"
                        onChange={this.toggleAutoLaunchProvider}
                        id="autoLaunchProvider"
                        value="autoLaunchProvider"
                        checked={this.state.autolaunchProvider}
                      />
                    }
                    label={formatMessage(messages.autoLaunchProviderText)}
                  />
                )}
              </Grid>
            </Grid>
          )}
          {showPassword && (
            <Fragment>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <LoginFormPassword />
                </Grid>
                {this.props.allowClassicLoginExperience &&
                  this.props.failedLogins >= 3 && (
                    <Grid container justify="flex-end">
                      <Grid item>
                        <OptOut />
                      </Grid>
                    </Grid>
                  )}
                <Grid item xs={8}>
                  <div className={classes.twelvePxPadding}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!this.props.username}
                          color="primary"
                          onChange={this.handleSaveUsername}
                          id="saveUsername"
                          value="saveUsername"
                          checked={this.props.remembered}
                        />
                      }
                      label={formatMessage(messages.saveUsername)}
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <ResetPasswordLink />
                </Grid>
              </Grid>
              <br />
              <Grid container justify="flex-end">
                <Grid item>
                  <BackToUserNameEntry />
                </Grid>
                <Grid item className={classes.padLeft10px}>
                  <Button
                    id="submitSignIn"
                    name="submitSignIn"
                    onClick={this.handleOnSubmit}
                    color="primary"
                    variant="contained"
                    disabled={!this.props.password}
                  >
                    {formatMessage(messages.signIn)}
                  </Button>
                </Grid>
              </Grid>
            </Fragment>
          )}
          <br />
        </div>
      </React.Fragment>
    );
  }
}

LoginForm.propTypes = {
  failedLogins: PropTypes.number.isRequired,
  setPassword: PropTypes.func.isRequired,
  loginWithPasswordWatcher: PropTypes.func.isRequired,
  username: PropTypes.string,
  password: PropTypes.string,
  classes: PropTypes.object.isRequired,
  intl: intlShape,
  passwordErrors: PropTypes.array,
  remembered: PropTypes.bool,
  step: PropTypes.string,
  autoIdpRedirectProviderData: providerShape,
  provider: providerShape,
  enableAutoIdpRedirect: PropTypes.bool,
  removeProvider: PropTypes.func.isRequired,
  rememberMe: PropTypes.bool,
  client_id: PropTypes.string,
  allowClassicLoginExperience: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    failedLogins: state.loginForm.failedLogins,
    remembered: state.loginForm.remembered,
    password: state.loginForm.password,
    username: state.loginForm.username,
    passwordErrors: state.loginForm.passwordErrors,
    step: state.loginForm.step,
    provider: state.loginForm.provider,
    enableAutoIdpRedirect: state.loginForm.enableAutoIdpRedirect,
    loginErrorMessages: state.loginForm.loginErrorMessages,
    deviceKey: state.loginForm.deviceKey,
    autoIdpRedirectProviderData: state.loginForm.autoIdpRedirectProviderData,
    client_id: state.loginForm.clientId,
    allowClassicLoginExperience: state.loginForm.allowClassicLoginExperience,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loginWithPasswordWatcher,
      removeProvider,
      rememberMe,
      // add other watcher sagas to this object to map them to props
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles)(withMobileDialog()(LoginForm))));
