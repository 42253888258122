import enUS from './lang/en-US.json';
import ploc from './lang/qps-PLOC.json';
import frCA from './lang/fr-CA.json';
import esMX from './lang/es-MX.json';

export default {
  'en-us': {
    localeName: "English (United States)",
    localeData: enUS
  },
  'fr-ca': {
    localeName: "Français",
    localeData: frCA
  },
  'qps-ploc': {
    localeName: "PseudoLoc",
    localeData: ploc
  },
  'es-mx': {
    localeName: "Español (Mexico)",
    localeData: esMX
  }
};
