import React, {Component} from 'react';
import styles from "../../styles";
import {Button, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {injectIntl, intlShape} from 'react-intl';
import {bindActionCreators} from "redux";
import messages from "../../messages";
import {resetPasswordWatcher} from "../../redux/actions/loginactioncreators";
import PropTypes from 'prop-types';
export class ResetPasswordLink extends Component {
  showPasswordResetDialog = () => {
    this.props.resetPasswordWatcher(
      {
        step: "RESET_PASSWORD",
        username: this.props.username.trim().toLowerCase()
      }
    )
  }

  render() {
    const {classes} = this.props;
    const {formatMessage} = this.props.intl;
    return (
      <Button
        id="forgotYourPassword"
        name="forgotYourPassword"
        className={classes.forgotPassword}
        onClick={this.showPasswordResetDialog}
      >
        {formatMessage(messages.forgotYourPassword)}
      </Button>
    )
  }
}

ResetPasswordLink.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape,
  username: PropTypes.string,
  locale: PropTypes.string,
  resetPasswordWatcher: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    username: state.loginForm.username,
    locale: state.intl.locale,
    passwordErrors: state.loginForm.passwordErrors
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    resetPasswordWatcher
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(ResetPasswordLink)));