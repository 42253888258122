import React, {Component} from 'react';
import {connect} from "react-redux";
import {loginWatcher} from "../../redux/actions/loginactioncreators";
import styles from "../../styles";
import {injectIntl, intlShape} from 'react-intl';
import {bindActionCreators} from "redux";
import messages from "../../messages";
import {Button, withStyles} from "@material-ui/core";
import PropTypes from 'prop-types';

class ResendVerificationCode extends Component {
  handleResendVerificationCode = () => {
    this.props.loginWatcher(
      {
        username: this.props.username,
        password: this.props.password,
        flowStage: 'RESEND_SMS_VERIFICATION_CODE'
      }
    )
  }

  render() {
    const {classes} = this.props;
    const {formatMessage} = this.props.intl;
    return (
      <Button
        id="submitResend"
        name="submitResend"
        className={classes.resendVerificationCode}
        onClick={this.handleResendVerificationCode}
      >
        {formatMessage(messages.smsResendButton)}
      </Button>
    )
  }
}
ResendVerificationCode.propTypes = {
  loginWatcher: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  intl: intlShape,
}
const mapStateToProps = state => {
  return {
    username: state.loginForm.username,
    password: state.loginForm.password,
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loginWatcher
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(ResendVerificationCode)));