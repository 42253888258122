const styles = theme => ({
  layout: {
    width: '100%',
    display: 'block', // Fix IE 11 issue.
    // marginLeft: theme.spacing.unit * 3,
    // marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  padLeft10px: {
    paddingLeft: "10px"
  },
  eightPxPadding: { padding: "8px 0px 8px 8px" },
  blurbArrow: { lineHeight: "30px", verticalAlign: "sub" },
  pictureStyle: { float: "right", maxHeight: '100vh', maxWidth: "calc(100%)" },
  twelvePxPadding: { padding: "12px" },
  boldFont: {
    fontWeight: 'bold'
  },
  tenPxPadRight: {paddingRight: "10px"},
  fivePxPadding: { padding: "5px" },
  error: {
    backgroundColor: "red",
    color: "white",
    minWidth: "100%",
    textAlign: "center",
    padding: "5px"
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  forgotPassword: {
    marginTop: '5px',
    textTransform: 'initial',
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  contactUsLink: {
    textTransform: 'initial',
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  learnMoreBlurb: {
    color: theme.palette.primary.main,
    display: "inline",
    fontSize: "0.875rem",
    fontFamily: "Open Sans, sans-serif",
    fontWeight: "bold",
    cursor: "pointer"
  },
  resendVerificationCode: {
    marginTop: '5px',
    textTransform: 'initial',
    color: theme.palette.primary.main,
    padding: "0px"
  },
  errorListTextItem: {
    color: "crimson",
    fontSize: '13px'
  },
  errorListText: {
    color: "crimson",
    minWidth: "100%",
    textAlign: "left",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    margin: "0px",
  },
  validationErrorsList: {
    padding: "0px"
  },
  footer: {
    margin: "4px"
  },
  InputLabel: {
    fontSize: "1.2em"
  },
});

export default styles;