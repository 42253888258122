import {defineMessages} from 'react-intl';

export default defineMessages({
  loginBlurb: {
    id: 'login.loginBlurb',
    defaultMessage: 'Welcome to the new single sign on for Sterling apps and services. After logging in with your\n' +
    '                  credentials, you will be able to link your Sterling accounts to one sign in.\n' +
    '                  Need an account? Create one here.',
    description: 'Login Blurb',
  },
  providerText: {
    id: 'login.providerText',
    defaultMessage: 'Launch your company login for single-sign on',
    description: 'Launch your company login for single-sign on',
  },
  autoLaunchProviderText: {
    id: 'login.autoLaunchProviderText',
    defaultMessage: 'Automatically launch your company login for single-sign on',
    description: 'Automatically launch your company login for single-sign on',
  },
  username: {
    id: 'login.username',
    defaultMessage: 'Username',
    description: 'Username',
  },
  password: {
    id: 'login.password',
    defaultMessage: 'Password',
    description: 'Password',
  },
  next: {
    id: 'login.next',
    defaultMessage: 'Next',
    description: 'Next Button'
  },
  signIn: {
    id: 'login.signIn',
    defaultMessage: 'Sign In',
    description: 'Sign In Button'
  },
  forgotYourPassword: {
    id: "login.forgotYourPassword",
    defaultMessage: "Forgot Password?",
    description: 'Forgot your password link'
  },
  saveUsername: {
    id: "login.saveUsername",
    defaultMessage: "Remember me",
    description: "Save username checkbox"
  },
  resetPassword: {
    id: 'login.resetPassword',
    defaultMessage: "Reset Password",
    description: "Reset Password Button"
  },
  cancel: {
    id: 'login.cancel',
    defaultMessage: 'Cancel',
    description: 'Cancel Button'
  },
  forgotPasswordTitle: {
    id: 'login.forgotPasswordTitle',
    defaultMessage: 'Forgot your password?',
    description: "Forgot password title"
  },
  forgotPasswordBlurb: {
    id: 'login.forgotPasswordBlurb',
    defaultMessage: 'An email with a verification code was sent to the email address associated with your login. If you do not receive a verification code, please contact support at the number provided in the \'Contact Us\' link below.',
    description: 'Forgot password blurb'
  },
  forgotPasswordMfaWarning: {
    id: 'login.forgotPasswordMfaWarning',
    defaultMessage: 'This will not reset your Multi-Factor Authentication method.',
    description: 'Forgot password blurb'
  },
  forgotPasswordPlaceholder: {
    id: 'login.forgotPasswordPlaceholder',
    defaultMessage: 'Password (8 Minimum)',
    description: 'Forgot Password Placeholder',
  },
  mfaSetupTitle: {
    id: 'login.mfaSetupTitle',
    defaultMessage: "Multi-Factor Authentication Setup"
  },
  mfaSetupBlurb: {
    id: 'login.mfaSetupBlurb',
    defaultMessage: "In order to protect your account, it is required to have one or more methods authentication to verify your identity"
  },
  mfaComplete: {
    id: 'login.mfaComplete',
    defaultMessage: 'Complete Setup'
  },
  codeEntry: {
    id: 'resetPassword.codeEntry',
    defaultMessage: "Verification Code"
  },
  passwordConfirm: {
    id: 'resetPassword.passwordConfirm',
    defaultMessage: "Confirm Password"
  },
  emailSender: {
    id: "resetPassword.emailSender",
    defaultMessage: "Look for an email from {sender}.  You may need to check your spam folder"
  },
  deliveryMediumEmail: {
    id: "resetPassword.deliveryMediumEmail",
    defaultMessage: "email"
  },
  deliveryMediumSms: {
    id: "resetPassword.deliveryMediumSms",
    defaultMessage: "text message"
  },
  totpBlurb: {
    id: "totp.blurb",
    defaultMessage: "Please use your Google Authenticator, or other Time-Based One-Time Password App to generate a code below."
  },
  totpVerifyButton: {
    id: "totp.verifyButton",
    defaultMessage: "Verify"
  },
  smsVerifyButton: {
    id: "sms.verifyButton",
    defaultMessage: "Verify"
  },
  smsResendButton: {
    id: "sms.resendButton",
    defaultMessage: "Resend verification code"
  },
  multiFactorTotpCodeEntry: {
    id: 'totp.codeEntryText',
    defaultMessage: 'Verification Code'
  },
  multiFactorSmsCodeEntry: {
    id: 'sms.codeEntryText',
    defaultMessage: 'Verification Code'
  },
  smsDestination: {
    id: "sms.destination",
    defaultMessage: "Type in the code sent to SMS number: {destination}"
  },
  chooseMfaType: {
    id: "mfa.chooseType",
    defaultMessage: "Select Your Preferred Method"
  },
  chooseMfaTypeDescription: {
    id: "mfa.chooseMfaTypeDescription",
    defaultMessage: "Select from your configured Multi-Factor Authentication methods below."
  },
  chooseMfaSmsText: {
    id: "mfa.chooseMfaSmsText",
    defaultMessage: "Receive Code via SMS {smsDeliveryDestination}"
  },
  chooseMfaTotpText: {
    id: "mfa.chooseMfaTotpText",
    defaultMessage: "Use your Time-Based One-Time Password authenticator"
  },
  chooseMfaButton: {
    id: "mfa.chooseMfaButton",
    defaultMessage: "Submit"
  },
  mfaSkip: {
    id: 'mfa.skipButton',
    defaultMessage: 'Skip'
  },
  backToUserNameEntry: {
    id: 'login.backToUserNameEntry',
    defaultMessage: 'Back',
  },
  newPasswordMustBeDifferent: {
    id: 'password.mustBeDifferent',
    defaultMessage: 'New password must be different than current password'
  },
  passwordMinLength: {
    id: 'password.minLength',
    defaultMessage: 'Password must have a minimum length of {minLength}'
  },
  passwordMaxLength: {
    id: 'password.maxLength',
    defaultMessage: 'Password must have a maximum length of {maxLength}'
  },
  passwordNumeric: {
    id: 'password.numeric',
    defaultMessage: 'Password must contain at least one numeric character'
  },
  passwordUppercase: {
    id: 'password.upperCase',
    defaultMessage: 'Password must contain at least one uppercase letter'
  },
  passwordLowercase: {
    id: 'password.lowerCase',
    defaultMessage: 'Password must contain at least one lowercase letter'
  },
  passwordSpecial: {
    id: 'password.special',
    defaultMessage: 'Password must contain at least one special character {specialCharacters}'
  },
  passwordRequirements: {
    id: 'password.requirements',
    defaultMessage: 'Due to recent changes, your existing password may not match the requirements.  Please Reset Password to change your password'
  },
  smsSetup: {
    id: 'mfaSetup.smsSetup',
    defaultMessage: "Mobile Phone Text Message"
  },
  smsSetupPhoneNext: {
    id: 'mfaSetup.smsSetupPhoneNext',
    defaultMessage: "Next"
  },
  smsSetupPhoneVerify: {
    id: 'mfaSetup.smsSetupPhoneVerify',
    defaultMessage: "Verify"
  },
  smsSetupPhoneVerifyBlurb: {
    id: "mfaSetup.smsSetupPhoneVerifyBlurb",
    defaultMessage: "Enter the verification code you received."
  },
  smsSetupPhoneResendBlurb: {
    id: "mfaSetup.smsSetupPhoneResendBlurb",
    defaultMessage: "Resend verification code"
  },
  smsSetupPhoneVerificationCode: {
    id: "mfaSetup.smsSetupPhoneVerificationCode",
    defaultMessage: "Verification Code"
  },
  totpSetup: {
    id: 'mfaSetup.totpSetup',
    defaultMessage: "Time-Based One-Time Password"
  },
  totpSetupBlurb: {
    id: "mfaSetup.totpSetupBlurb",
    defaultMessage: "Use Time-Based One-Time Passwords (TOTP) via a software application such as Google Authenticator"
  },
  totpSetupScanBlurb: {
    id: "mfaSetup.totpSetupScanBlurb",
    defaultMessage: "Scan the barcode above into your App, then enter the 6 digit value generated before the time runs out."
  },
  totpSetupSecretCodeLabel: {
    id: "mfaSetup.totpSetupSecretCodeLabel",
    defaultMessage: "Can't scan the barcode? Enter the key provided below manually into your App to generate the 6 digit value."
  },
  totpSetupVerificationCode: {
    id: "mfaSetup.totpSetupVerificationCode",
    defaultMessage: "Verification Code"
  },
  totpSaveButton: {
    id: "mfaSetup.totpSaveButton",
    defaultMessage: "Verify"
  },
  mfaEnterCode: {
    id: "mfa.enterCode",
    defaultMessage: "Enter code"
  },
  mfaSaveDevice: {
    id: 'mfa.saveDevice',
    defaultMessage: "Remember this device for 30 days"
  },
  loginFirstTimeSuccess: {
    id: 'login.firstTimeSuccess',
    defaultMessage: "Success!"
  },
  loginFirstTimeBlurb: {
    id: 'login.firstTimeBlurb',
    defaultMessage: "You may continue directly into your secure account or add another Sterling account in your Account Dashboard."
  },
  loginFirstTimeContinueToDashboard: {
    id: 'login.firstTimeContinueToDashboard',
    defaultMessage: "Add Account"
  },
  loginFirstTimeAutoLaunch: {
    id: 'login.firstTimeAutoLaunch',
    defaultMessage: "Continue"
  },
  changePasswordPasswordsDoNotMatch: {
    id: 'login.changePasswordPasswordsDoNotMatch',
    defaultMessage: "Passwords do not match"
  },
  contactUsText: {
    id: 'login.contactUsText',
    defaultMessage: 'Contact Us'
  },
  changeEmailBlurb: {
    id: 'login.changeEmailBlurb',
    defaultMessage: 'Your email address is in use by a different login.  Please change your email address.  A confirmation email will be sent to the new address with a code.'
  },
  emailAddress: {
    id: 'login.emailAddress',
    defaultMessage: 'Email Address',
  },
  changeEmailSubmit: {
    id: 'login.changeEmailSubmit',
    defaultMessage: 'Submit',
  },
  confirmEmailBlurb: {
    id: 'login.confirmEmailBlurb',
    defaultMessage: 'An email was sent to {destination} with a verification code.  Please type it into the input below to confirm your email address.'
  },
  emailAddressConfirmationCode: {
    id: 'login.emailAddressConfirmationCode',
    defaultMessage: 'Confirmation Code',
  },
  cancelSkipEmailSubmit: {
    id: 'login.cancelSkipEmailSubmit',
    defaultMessage: 'Cancel',
  },
  skipEmailSubmit: {
    id: 'login.skipEmailSubmit',
    defaultMessage: 'Skip This Step',
  },
  confirmEmailSubmit: {
    id: 'login.confirmEmailSubmit',
    defaultMessage: 'Confirm Email',
  },
  forcedResetPasswordBlurb: {
    id: 'forcedResetPassword.Blurb',
    defaultMessage: 'To keep every account secure, an email with a verification code was sent to the email address associated with your login. If you do not receive a verification code, please contact support at the number provided in the \'Contact Us\' link below.',
    description: 'Reset Password Title blurb'
  },
  forcedResetPasswordVerifyCode: {
    id: 'forcedResetPassword.verifyCode',
    defaultMessage: "Verification Code"
  },
  forcedResetPasswordConfirmPassword: {
    id: 'forcedResetPassword.confirmPassword',
    defaultMessage: "Confirm Password"
  },
  forcedResetPasswordPasswordMin: {
    id: 'forcedResetPassword.passwordMin',
    defaultMessage: 'Password (8 Minimum)',
    description: 'Password Min 8 chars',
  },
  forcedResetPasswordBlurbTitle: {
    id: 'forcedResetPassword.blurbTitle',
    defaultMessage: 'Reset your password, please',
    description: "Reset password blurb title"
  },
  tooManyFailedAttemptsException: {
      id: "tooManyFailedAttemptsException",
      defaultMessage: "Your account has been temporarily locked due to multiple invalid login attempts, and may take up to 15 minutes to automatically unlock"
  },
  NotAuthorizedException: {
    id: "NotAuthorizedException",
    defaultMessage: "User does not exist or could not be authenticated",
    description: "Incorrect credential message"
  },
  forcedResetPasswordCancel: {
    id: 'forcedResetPassword.cancel',
    defaultMessage: 'Cancel',
    description: 'Cancel Button'
  },
  forcedResetPasswordResetPassword: {
    id: 'forcedResetPassword.resetPassword',
    defaultMessage: "Reset Password",
    description: "Reset Password Button"
  },
  existingSession: {
    id:"login.existingSession",
    defaultMessage: "This username is already in use. Would you like to disconnect them so you may stay signed in?",
    description: "An existing session was detected"
  },
  existingSessionSignOut: {
    id:"login.signOut",
    defaultMessage: "Sign out",
    description: "Sign out button"
  },
  existingSessionStaySignedIn: {
    id:"login.staySignedIn",
    defaultMessage: "Stay signed in",
    description: "Stay signed in button"
  },
  CodeMismatchException: {
    id:"codeMismatchException",
    defaultMessage: "Invalid authorization code.",
    description: "An invalid MFA code was entered"
  },
  unknownErrorException: {
    id:"unknownErrorException",
    defaultMessage: "An unknown error has occurred, please try again later.",
    description: "An error that hasn't been documented and/or translated"
  },
  InvalidUsername: {
    id:"invalidUsername",
    defaultMessage: "This username appears invalid, please try again",
    description: "An invalid username was entered"
  },
  UsernameContainsSpaces: {
    id:"usernameContainsSpaces",
    defaultMessage: "The username contains spaces",
    description: "An invalid username was entered"
  }
});
