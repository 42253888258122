import React, {Component} from 'react';
import {Button, Grid, Typography, withMobileDialog, withStyles} from "@material-ui/core";
import {injectIntl, intlShape} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {answerMfaChoiceChallenge} from '../../redux/actions/loginactioncreators';
import messages from '../../messages';
import PropTypes from 'prop-types';
import challengeShape from "../../proptypes/challengeShape";

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  selectButton: {
    paddingTop: "25px",
    paddingBottom: "25px",
    marginTop: "10px",
    color: "#000"
  }
});

class MfaChoice extends Component {

  handleEnable = mfaType => {
    this.props.answerMfaChoiceChallenge(mfaType)
  }

  render() {
    const {formatMessage} = this.props.intl;
    const {classes} = this.props;
    return (
      <main className={classes.layout}>
        <Grid item xs={12}>
          <Grid container justify="flex-start">
            <Grid item xs={12}>
              <Typography variant="h6">{formatMessage(messages.chooseMfaType)}</Typography>
              <Typography>{formatMessage(messages.chooseMfaTypeDescription)}</Typography>
              <br/>
              <Button className={classes.selectButton}
                      fullWidth={true}
                      onClick={() => this.handleEnable("SMS_MFA")}
                      name="chooseSms"
                      id="chooseSms"
                      variant="outlined"
                      color="primary">
                {formatMessage(messages.chooseMfaSmsText, {smsDeliveryDestination: this.props.challenge.challengeParameters.CODE_DELIVERY_DESTINATION})}
              </Button>

              <Button className={classes.selectButton}
                      fullWidth={true}
                      onClick={() => this.handleEnable("SOFTWARE_TOKEN_MFA")}
                      name="chooseTotp"
                      id="chooseTotp"
                      variant="outlined"
                      color="primary">
                {formatMessage(messages.chooseMfaTotpText)}
              </Button>

            </Grid>
          </Grid>
        </Grid>
      </main>
    )
  }
}

MfaChoice.propTypes = {
  classes: PropTypes.object.isRequired,
  challenge: challengeShape,
  intl: intlShape,
  answerMfaChoiceChallenge: PropTypes.func.isRequired,
  deviceKey: PropTypes.string,
}
const mapStateToProps = state => {
  return {
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge,
    deviceKey: state.loginForm.deviceKey
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    answerMfaChoiceChallenge
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(withMobileDialog()(MfaChoice))))