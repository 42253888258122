import React, { Component } from 'react';
import { Button, Grid, TextField, Typography, withMobileDialog, withStyles } from "@material-ui/core";
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { cancelResetPasswordWatcher, resetPasswordWatcher } from '../../redux/actions/loginactioncreators';
import messages from "../../messages";
import PasswordField from '../fields/PasswordField';
import CognitoPasswordValidator from "../changePasswordForm/CognitoPasswordValidator";
import PropTypes from 'prop-types';
import challengeShape from "../../proptypes/challengeShape";

const validator = new CognitoPasswordValidator(8, 99, true, true, true, true);
const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  InputLabel: {
    fontSize: "1.2em"
  },
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  }
});

export class ForcedResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loginErrorMessages: [],
      validationErrors: {},
      username: null,
      password: null,

      newPassword: {
        value: '',
        valid: false
      },
      code: {
        value: '',
        valid: false
      },
      verifyPassword: {
        value: '',
        valid: false
      },
      submitEnabled: false,
    }
  }

  handleCodeChange = event => {
    this.setState({
      code: {
        value: event.target.value,
        valid: event.target.value ? event.target.value.length > 0 : false
      },
      submitEnabled: this.state.newPassword.valid && this.state.verifyPassword.valid && event.target.value ? event.target.value.length > 0 : false
    });
  }

  handlePasswordChange = event => {
    const pwdInputId = 'newPassword';
    const currentPWDValue = document.getElementById(pwdInputId).value;
    const matchPwdInputId = 'newPasswordConfirm';
    const matchValue = document.getElementById(matchPwdInputId).value;
    const errors = [];

    errors.push(...validator.validatePasswordFormat(currentPWDValue));
    errors.push(...validator.validatePasswordRules(currentPWDValue, matchValue));
    let isValid = errors.length === 0 ? true : false;

    this.setState({
      newPassword: {
        value: event.target.password,
        valid: isValid
      },
      submitEnabled: this.state.code.valid && this.state.verifyPassword.valid && isValid
    });
  }

  handleVerifyPasswordChange = event => {
    const pwdInputId = 'newPasswordConfirm';
    const currentPWDValue = document.getElementById(pwdInputId).value;
    const matchPwdInputId = 'newPassword';
    const matchValue = document.getElementById(matchPwdInputId).value;
    const errors = [];

    errors.push(...validator.validatePasswordFormat(currentPWDValue));
    errors.push(...validator.validatePasswordRules(currentPWDValue, matchValue));
    const isValid = errors.length === 0 ? true : false;

    this.setState({
      verifyPassword: {
        value: event.target.password,
        valid: isValid
      },
      submitEnabled: this.state.code.valid && this.state.newPassword.valid && isValid
    });
  }

  handleCloseResetPassword = () => {
    this.props.cancelResetPasswordWatcher()
  }

  handleOnSubmit = () => {
    this.props.resetPasswordWatcher(
      {
        step: "CONFIRM_RESET_PASSWORD",
        username: this.props.username.trim().toLowerCase(),
        code: this.state.code.value.trim(),
        password: this.state.newPassword.value.trim()
      }
    )
  }

  render() {
    const { classes } = this.props;
    const { formatMessage } = this.props.intl;

    const deliveryMedium = this.props.challenge.challengeParameters.CodeDeliveryDetails.DeliveryMedium;
    let deliveryMediumText = formatMessage(messages.deliveryMediumEmail);
    if (deliveryMedium === 'SMS') {
      deliveryMediumText = formatMessage(messages.deliveryMediumSms);
    }

    return (
      <main className={classes.layout} id="S1Transport">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">{formatMessage(messages.forcedResetPasswordBlurbTitle)}</Typography>
            <Typography>
              {formatMessage(messages.forcedResetPasswordBlurb, {
                deliveryMedium: deliveryMediumText
              })}
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="code"
              name="code"
              className={classes.textField}
              label={formatMessage(messages.forcedResetPasswordVerifyCode)}
              InputLabelProps={{
                className: classes.InputLabel,
              }}
              onChange={this.handleCodeChange}
              error={this.state.validationErrors.code}
              autoFocus={true}
              fullWidth={true}
            />
            <PasswordField
              id="newPassword"
              name="newPassword"
              label={formatMessage(messages.forcedResetPasswordPasswordMin)}
              passwordValidator={validator}
              onChange={this.handlePasswordChange}
              fullWidth={true}
            />
            <PasswordField
              id="newPasswordConfirm"
              name="newPasswordConfirm"
              label={formatMessage(messages.forcedResetPasswordConfirmPassword)}
              match={this.state.newPassword.value}
              passwordValidator={validator}
              onChange={this.handleVerifyPasswordChange}
              fullWidth={true}
            />
          </Grid>
        </Grid>
            <br />
        <Grid container justify="flex-end">
          <Grid item>
            <Button
              id="cancelResetPassword"
              name="cancelResetPassword"
              onClick={this.handleCloseResetPassword}
              className={classes.signInButton}
              variant="outlined"
              color="primary">{formatMessage(messages.forcedResetPasswordCancel)}
            </Button>
          </Grid>
          <Grid item
            className={classes.padLeft10px}>
            <Button
              id="submitResetPassword"
              name="submitResetPassword"
              onClick={this.handleOnSubmit}
              className={classes.signInButton}
              variant="contained"
              disabled={!this.state.submitEnabled}
              color="primary">{formatMessage(messages.forcedResetPasswordResetPassword)}</Button>
          </Grid>
        </Grid>
      </main>
    )
  }

}
ForcedResetPassword.propTypes = {
  challenge: challengeShape,
  intl: intlShape,
  username: PropTypes.string,
  classes: PropTypes.object.isRequired,
  resetPasswordWatcher: PropTypes.func.isRequired,
  cancelResetPasswordWatcher: PropTypes.func.isRequired,
}
const mapStateToProps = state => {
  return {
    username: state.loginForm.username,
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge || null
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    resetPasswordWatcher,
    cancelResetPasswordWatcher
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(withMobileDialog()(ForcedResetPassword))))
